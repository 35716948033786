import React from 'react';
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";

const VotesContainer = props => {
    const { upvotes, downvotes } = props;
    return (
        <div className={`flex votes-container`}>
            <FaRegThumbsUp className={`vote-element`} size={12} />
            <div className={`vote-element`}>
                {upvotes}
            </div>
            <FaRegThumbsDown className={`vote-element`} size={12} />
            <div className={`vote-element`}>
                {downvotes}
            </div>
        </div>
    )
}

export default VotesContainer;