import React, { useState } from 'react';
import {Link, useNavigate} from "react-router-dom";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

import { register, authenticate } from "../../utilities/data";
import BackArrow from "../shared/BackArrow";
import TermsAndConditions from "../termsandconditions/TermsAndConditions";

const Register = props => {

  const [ username, setUsername ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ firstName, setFirstName ] = useState("");
  const [ lastName, setLastName ] = useState("");
  const [ isRegisteringUser, setIsRegisteringUser ] = useState(false);
  const [ isProblemLoggingIn, setIsProblemLoggingIn ] = useState(false);
  const [ message, setMessage ] = useState("");
  const [ isTermsAndConditionsConsent, setIsTermsAndConditionsConsent ] = useState();

  const [ modal, setModal ] = useState(false);

  const navigate = useNavigate();

  const displayError = async () => {
    setMessage("Sorry an error occurred - please try again. If the problem persists please contact support@placecheck.info for assistance.");
    setIsRegisteringUser(false)
  }

  const registerUser = async () => {
    if (!isTermsAndConditionsConsent) {
      setMessage("You must agree to the terms and conditions in order to use Placecheck");
      return;
    }

    setIsRegisteringUser(true);
    setIsProblemLoggingIn(false);
    setMessage("");

    try {
      let registerData = {
        username,
        password,
        firstName,
        lastName
      }

      const registerResult = await register(registerData);

      if (registerResult.data.isSuccess === true) {
        const authResult = await authenticate(username, password);
        if (authResult.data.isAuthenticated) {
          await props.updateAppState({
            userID: Number(authResult.data.userID),
            token: authResult.data.token,
            isRegisteringUser: false
          });
        }

        navigate(`/maps/${props.slug !== null ? props.slug : ""}`);
      } else {
        let messageText = "Sorry an error occurred. Please contact support@placecheck.info for assistance";

        if (registerResult.data.errorCode === "user_exists")
          messageText = "A user with this username already exists. Please contact support@placecheck.info if you need assistance.";

        setMessage(messageText);
        setIsRegisteringUser(false);
      }
    }
    catch (e) {
      // console.log("Error", e)
      displayError();
    }
  }

  const showTermsAndConditions = e => {
    e.preventDefault();
    toggle();
  }

  const toggle = () => {
    setModal(!modal);
  }

  const handleTextInput = async e => {
    if (e.currentTarget.name === "username")
      setUsername(e.currentTarget.value);
    if (e.currentTarget.name === "password")
      setPassword(e.currentTarget.value);
    if (e.currentTarget.name === "firstName")
      setFirstName(e.currentTarget.value);
    if (e.currentTarget.name === "lastName")
      setLastName(e.currentTarget.value);
  }

  const handleCheckBox = e => {
    setIsTermsAndConditionsConsent(!isTermsAndConditionsConsent);
  }

  return (
    <div className={`login-form-container`}>
      <div className={`login-form`}>
        <div className="d-flex justify-content-start mb-2">
          <div style={{width: "30px"}}>
            <BackArrow to={`/maps/${props.slug !== null ? props.slug : ""}`} />
          </div>
          <h3 className="text-center w-100" style={{paddingRight: "30px"}}>Create account</h3>

        </div>
        <div className={`form-control border-0`}>
          <div className={`form-label`}>First name</div>
          <div>
            <input className={`form-input`} name="firstName" type="text" value={firstName} onChange={handleTextInput} />
          </div>
        </div>
        <div className={`form-control border-0`}>
          <div className={`form-label`}>Last name</div>
          <div>
            <input className={`form-input`} name="lastName" type="text" value={lastName} onChange={handleTextInput} />
          </div>
        </div>
        <div className={`form-control border-0`}>
          <div className={`form-label`}>Email</div>
          <div>
            <input className={`form-input`} name="username" type="email" value={username} onChange={handleTextInput} />
          </div>
        </div>
        <div className={`form-control border-0`}>
          <div className={`form-label`}>
            Password
          </div>
          <div>
            <input className={`form-input`} name="password" type="password" value={password} onChange={handleTextInput} />
          </div>
        </div>
        <div className="form-control border-0">
          <div className="form-check">
            <input
                onClick={handleCheckBox}
                name="termsAndConditionsConsent"
                className="form-check-input"
                type="checkbox"
                checked={isTermsAndConditionsConsent}
                id="termsAndConditionsConsent"
            />
            <label className="form-check-label" htmlFor="termsAndConditionsConsent">
              I agree to the <a className="text-primary" style={{cursor: "pointer"}} onClick={showTermsAndConditions}>Terms and Conditions</a>
            </label>
          </div>
        </div>
        <div>

        </div>
      </div>

      <div>
        <button
            className={`button`}
            onClick={registerUser}
        >
          {isRegisteringUser ? `Please wait` : `Sign up`}
        </button>
      </div>
      <div className={`login-error-message`}>
        {message}
      </div>

      <Link to={`/forgotPassword`} className={`login-forgotten-password-link`}>
        Click here if you already have an account but have forgotten your password?
      </Link>

      <div className={`login-notes`}>
        <p>
          <strong>Privacy notice</strong>
        </p>
        <p>
          We have asked for personal information from you.
          This information is necessary for one of the reasons specified in the General Data Protection Regulation.
          If you have any questions or concerns, please contact <a href="mailto:support@placecheck.info">support@placecheck.info</a>.
        </p>
        <p>
          This website also makes use of cookies which are all necessary for the services provided by the website.
          For more information please read our full privacy notice at <a href="http://placecheck.info/en/privacy-policy" rel="noopener noreferrer" target="_blank">http://placecheck.info/en/privacy-policy</a>
        </p>
      </div>

      <Modal isOpen={modal} toggle={toggle} size={'lg'}>
        <ModalHeader toggle={toggle}>Placecheck Terms and Conditions</ModalHeader>
        <ModalBody>
          <TermsAndConditions />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default Register;
