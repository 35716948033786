import React from 'react';

const QuestionnaireSubmitConfirmation = props => {

  // console.log("QuestionnaireSubmitConfirmation", props)

  const reload = () => {
    window.location.reload();
  }

  if (props.hasSubmitQuestions === true) {
    return (
      <div className="questionnaire-submit">
        <h1>Thank you - your answers have been submitted</h1>
        <p>
          Thank you for responding to this consultation.
        </p>
        <div className="flex-collapse">
          <div className="button" onClick={reload}>Finish</div>
        </div>
      </div>
    )
  }

  return (
    <div className="questionnaire-submit">
      <h1>Submit questionnaire?</h1>
      <p>
        Thank you for responding to this consultation.
      </p>
      <p>
        You have answered {props.answeredQuestions.length} question{props.answeredQuestions.length !== 1 ? 's' : ''} out of {props.questions.length}, and have added {props.questionnaireMarkers.length} comment{props.questionnaireMarkers.length !== 1 ? 's' : ''} with a map pin.
      </p>
      <p>
        You can select 'Go Back' to review or change your answers on the map.
      </p>
      <p>
        If you select 'Proceed' then your answers will be submitted and all questions will be reset.
      </p>
      <div className="flex-collapse">
        <div className="button" style={{backgroundColor: "#999999"}} onClick={props.cancelSubmitQuestionnaire}>Go Back</div>
        <div className="button" onClick={props.sendQuestionnaireResults}>Proceed</div>
      </div>
    </div>
  )
}

export default QuestionnaireSubmitConfirmation;