import React from 'react';
import { AiOutlineCamera } from "react-icons/ai";
import getFileSizeDescription from "../utilities/getFileSizeDescription";

const mapsWithManualUpload = [441, 466];

class AddImagePanel extends React.Component {
    state = {
        imageName: "",
        imageSize: "",
        isUploading: false
    }
    deleteImage = async () => {
        await this.props.updatePopupContentState({
            imageFile: null,
            isDeleteImageWhenSavingMarker: true
        });
        await this.setState({
            imageName: "",
            imageSize: ""
        });
    }
    isMobile = () => {
        return window.innerWidth < 650;
    }
    previewImage = async e => {
        let imageFile = e.target.files[0];
        await this.setState({
            imageName: imageFile.name,
            imageSize: imageFile.size
        })

        const reader = new FileReader();
        reader.onload = () => {
            let displayImage = document.getElementById('displayImage');
            displayImage.src = reader.result;
        }
        await reader.readAsDataURL(imageFile);
        await this.props.updatePopupContentState({imageFile: imageFile});
    }
    render = () => {

        if (this.state.isUploading) {
            return (
                <div className={`flex-center`}>
                    Uploading
                </div>
            )
        }

        if (this.state.imageName === "") {
            return (
                <React.Fragment>
                    <div className={`file-upload text-center p-2 mb-1`}>
                        <div>
                            <AiOutlineCamera size={32} />
                        </div>
                        {
                            this.isMobile() === true && mapsWithManualUpload.indexOf(Number(this.props.mapID)) !== -1
                            &&
                            <div>
                                Take photo with camera (if available)
                            </div>
                        }
                        <div>
                            <label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    capture="camera"
                                    onChange={this.previewImage}
                                />
                            </label>
                        </div>
                    </div>
                    {
                        this.isMobile() === true && mapsWithManualUpload.indexOf(Number(this.props.mapID)) !== -1
                        &&
                        <div className={`file-upload text-center p-2 mb-1`}>
                            <div>
                                <AiOutlineCamera size={32} />
                            </div>
                            <div>
                                Manually upload image from device
                            </div>
                            <div>
                                <label>
                                    <input
                                        type="file"
                                        // accept="image/*"
                                        // capture="camera"
                                        onChange={this.previewImage}
                                    />
                                </label>
                            </div>
                        </div>
                    }
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    <div>
                        <img
                            id="displayImage"
                            alt="preview"
                            className="display-image"
                        />
                    </div>
                    <div className={`d-flex justify-content-between mt-1`}>
                        <div style={{fontSize: "0.8em"}}>
                          {this.state.imageName} ({getFileSizeDescription(this.state.imageSize)})
                        </div>
                        <div>
                            <div
                                onClick={this.deleteImage}
                                className={`btn btn-sm btn-secondary`}
                                style={{color:"#ffffff"}}
                            >
                                Remove
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default AddImagePanel;



