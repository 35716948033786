import React from 'react';
import { v4 as uuid } from "uuid";

import {addMarker, uploadImage} from "../../utilities/data";
import getAddMarkerFormLabels from "./utilities/getAddMarkerFormLabels";
import AddImagePanel from "./popup-content/AddImagePanel";
import AdditionalDataInputs from "./AdditionalDataInputs";
import BackArrow from "../shared/BackArrow";
import parseAdditionalData from "../../utilities/parseAdditionalData";

class AddMarkerForm extends React.Component {
  state = {
    isSaving: false,
    title: "",
    description: "",
    additionalMarkerControls: [],
    additionalData: {},
    errorCount: 0,
    errorMessage: "",
    imageFile: null,
    isDeleteImageWhenSavingMarker: false
  }
  componentDidMount = async () => {
    if (this.props.mapData.map.additionalMarkerControls !== null) {
      const additionalControls = parseAdditionalData(this.props.mapData.map.additionalMarkerControls);

      const defaultAdditionalData = {};
      additionalControls.forEach(control => {
        if (control.type === 'checkbox')
          defaultAdditionalData[control.id] = control.defaultValue === 1;
        else if (control.type !== 'title')
          defaultAdditionalData[control.id] = control.defaultValue;
      })

      await this.setState({
        additionalMarkerControls: additionalControls,
        additionalData: defaultAdditionalData
      })
    }

  }
  handleTextInput = async e => {
    let a = {};
    a[e.currentTarget.name] = e.currentTarget.value;
    await this.setState(a);
  }
  handleAdditionalDataInput = async e => {
    let tempAdditionalData = this.state.additionalData;
    if (e.currentTarget.type === 'checkbox') {
      tempAdditionalData[e.currentTarget.id] = e.currentTarget.checked;
    }
    else if (e.currentTarget.type === 'text') {
      tempAdditionalData[e.currentTarget.id] = e.currentTarget.value;
    }
    await this.setState({additionalData: tempAdditionalData});
  }
  updateAddMarkerFormState = async obj => {
    await this.setState(obj);
  }
  save = async () => {
    await this.setState({
      isSaving: true
    });

    let addMarkerData = {
      "mapID": this.props.mapID,
      "layerID": this.props.layerID,
      "token": this.props.token,
      "title": this.state.title,
      "description": this.state.description,
      "additionalData": this.state.additionalData,
      "markerLocation": this.props.markerLocation
    };

    if (this.props.mapData.map.specialType === 'questionnaire') {

      // console.log("Saving questionnaire marker", this.props)

      addMarkerData.id = uuid();
      addMarkerData.name = this.state.title;
      addMarkerData.lat = this.props.markerLocation[0];
      addMarkerData.lng = this.props.markerLocation[1];
      addMarkerData.additionalData = "";

      await this.props.addQuestionnaireMarker(addMarkerData);
      await this.setState({isSaving: false});
      await this.props.updateMapState({
        addMarkerLayer: null,
        addMarkerName: "",
        addMarkerDescription: "",
        markerLocation: [],
        addMarkerStage: 0
      });
    }
    else {
      const addMarkerResponse = await addMarker(addMarkerData);

      if (addMarkerResponse.data.isSuccess === true) {

        if (this.state.imageFile !== null)
          await uploadImage(this.props.token, addMarkerResponse.data.markerID, this.state.imageFile);

        await this.props.loadMapData();
        await this.setState({isSaving: false});
        await this.props.updateMapState({
          addMarkerLayer: null,
          addMarkerName: "",
          addMarkerDescription: "",
          markerLocation: [],
          addMarkerStage: 0
        });
      } else {
        alert("Error adding marker")
        if (this.state.errorCount < 3) {
          await this.setState({
            errorCount: this.state.errorCount + 1,
            errorMessage: "Sorry, an error occurred while saving. Please try again."
          })
        } else {
          await this.setState({
            errorMessage: "Please contact support@placecheck.info"
          })
        }
      }
    }

  }
  render() {
    return (
      <div className={`add-marker-form-container`}>
        <div className={`add-marker-form`}>
          <div className="d-flex justify-content-start">
            <div style={{width: "30px"}}>
              <BackArrow action={() => this.props.updateMapState({addMarkerStage: 1})} />
            </div>
            <h3 className="text-center w-100" style={{paddingRight: "30px"}}>Add new map pin</h3>
          </div>

          <div>

            <div className={`form-control border-0`}>
              <div className={`form-label`}>
                {getAddMarkerFormLabels(this.props.layerID).name}
              </div>
              <div>
                <input className={`form-input`} name="title" type="text" value={this.state.title} onChange={this.handleTextInput} />
              </div>
            </div>
            <div className={`form-control border-0`}>
              <div className={`form-label`}>
                {getAddMarkerFormLabels(this.props.layerID).description}
              </div>
              <div>
                <textarea
                    className={`form-input`}
                    name="description"
                    value={this.state.description}
                    onChange={this.handleTextInput}
                />
              </div>
            </div>
            {
              this.state.additionalMarkerControls !== undefined
              &&
              <AdditionalDataInputs
                additionalMarkerControls={this.state.additionalMarkerControls}
                handleAdditionalDataInput={this.handleAdditionalDataInput}
              />
            }
            {
              this.props.mapData.map.isPhotos === "1"
              &&
              <div className={`form-control border-0 my-2`}>
                <h5 className="text-center">Add a photo (optional)</h5>
                <div>
                  <AddImagePanel
                      mapID={this.props.mapID}
                      updatePopupContentState={this.updateAddMarkerFormState}
                  />
                </div>
              </div>
            }
            <div className={`form-control border-0`}>
              <div className={`flex`}>
                <div className={`btn btn-secondary`} onClick={() => this.props.updateMapState({addMarkerStage: 1})}>
                  Cancel
                </div>
                <button className={`btn btn-primary px-4`} onClick={this.save}>
                  {this.state.isSaving ? `Saving` : `Save`}
                </button>
              </div>
            </div>
            {
              this.state.errorMessage !== ""
              &&
              <div className={`login-error-message`}>
                {this.state.errorMessage}
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default AddMarkerForm;
