import React, {useEffect, useState} from 'react';
import {FormGroup, Label, Input} from "reactstrap";
import getAnswerObject from "../utilities/getAnswerObject";

const RadioGroup = props => {
  const [value, setValue] = useState(null);
  const [otherValue, setOtherValue] = useState("");
  const {forminput} = props;
  const options = forminput.options.split("|");

  useEffect(() => {
    // Check if question has already been answered and set value
    props.answeredQuestions.forEach(answer => {
      if (answer.id === `radio-${forminput.questionID}-${forminput.id}`) {
        setValue(answer.value);
        setOtherValue(answer.otherValue);
      }
    })
  }, [props.answeredQuestions])

  const saveAnswer = answer => {
    let newAnsweredQuestions = [...props.answeredQuestions];
    let isAnswered = false;
    newAnsweredQuestions = newAnsweredQuestions.map(answeredQuestion => {
      if (answeredQuestion.id === answer.id) {
        isAnswered = true;
        return Object.assign(answeredQuestion, answer);
      }
      else
        return answeredQuestion;
    })
    if (isAnswered === false)
      newAnsweredQuestions.push(answer);
    props.updateMapState({answeredQuestions: newAnsweredQuestions})

    //console.log("finishing save answer", newAnsweredQuestions)
  }

  const handleOtherTextInput = e => {
    //console.log("Handling other text input", e.target.name, e.target.value);
    let code = `${e.target.name}-${options.length - 1}`;
    let answer = getAnswerObject(code, e.target.value);
    //console.log("Other text input answer object", answer)
    //console.log("Should be setting choice value to " + (options.length - 1));
    setValue(options.length - 1);
    setOtherValue(e.target.value);
    saveAnswer(answer);
  }

  const handleRadioClick = e => {
    //console.log("Radio button clicked ", e.target.name, e.target.value)

    let answer = getAnswerObject(e.target.value);
    //console.log("Radio button input answer object", answer)
    setValue(answer.value);
    saveAnswer(answer);
  }

  return (
    <div className="placecheck-question">
      <FormGroup tag="fieldset">
        <legend>{forminput.questionText}</legend>
        {
          options.map((option, index) => {
            return (
              <FormGroup key={`radio-${forminput.id}-${index}`}check>
                <Label check>
                  <Input
                    type="radio"
                    name={`radio-${forminput.questionID}-${forminput.id}`}
                    onChange={handleRadioClick}
                    value={`radio-${forminput.questionID}-${forminput.id}-${index}`}
                    checked={Number(value) === index && value !== null}
                  />
                  {' '}
                  {option}
                </Label>
              </FormGroup>
            )
          })
        }
        {
          (forminput.lastOptionTextInputLabel !== "" && forminput.lastOptionTextInputLabel !== null)
          &&
          <FormGroup>
            <Label for="exampleEmail">{forminput.lastOptionTextInputLabel}</Label>
            <Input
              type="text"
              name={`radioother-${forminput.questionID}-${forminput.id}`}
              id={`radioother-${forminput.questionID}-${forminput.id}`}
              placeholder=""
              value={otherValue}
              onChange={handleOtherTextInput}
            />
          </FormGroup>
        }
      </FormGroup>
    </div>
  )
}

export default RadioGroup;