import React, {useEffect, useState} from 'react';
import {FormGroup, Label, Input} from "reactstrap";
import getAnswerObject from "../utilities/getAnswerObject";

const TextInput = props => {
  const [value, setValue] = useState();
  const {forminput} = props;

  useEffect(() => {
    // Check if question has already been answered and set value
    let value = "";
    props.answeredQuestions.forEach(answer => {
      if (answer.id === `textarea-${forminput.questionID}-${forminput.id}`)
        value = answer.value;
    })
    setValue(value);
  }, [props.answeredQuestions])

  const handleTextChange = e => {
    //console.log("Textarea changed", e.target.name, e.target.value)
    let answer = getAnswerObject(e.target.name, e.target.value);
    //console.log("Answer object created", answer)
    let newAnsweredQuestions = [...props.answeredQuestions];
    //console.log("newAnsweredQuestions created", newAnsweredQuestions)
    let isAnswered = false;
    newAnsweredQuestions = newAnsweredQuestions.map(answeredQuestion => {
      if (answeredQuestion.id === answer.id) {
        isAnswered = true;
        return answer;
      }
      else
        return answeredQuestion;
    })
    if (isAnswered === false)
      newAnsweredQuestions.push(answer);
    //console.log("Filter process done. New version", newAnsweredQuestions)
    props.updateMapState({answeredQuestions: newAnsweredQuestions})
  }

  return (
    <FormGroup>
      <Label for="exampleText">{props.forminput.questionText}</Label>
      <Input
        rows={3}
        type="textarea"
        value={value}
        name={`textarea-${forminput.questionID}-${forminput.id}`}
        id={`textarea-${forminput.questionID}-${forminput.id}`}
        onChange={handleTextChange}
      />
    </FormGroup>
  )
}

export default TextInput;