import React from 'react';
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const BackArrow = props => {
  if (props.to !== undefined) {
    return (
      <div className={`back-arrow`}>
        <Link to={props.to}>
          <FaArrowLeft style={{color: "#00146d"}} size={24}/>
        </Link>
      </div>
    )
  }
  else if (props.action !== undefined) {
    return (
      <div className={`back-arrow`} onClick={props.action}>
          <FaArrowLeft style={{color: "#00146d"}} size={24}/>
      </div>
    )
  }
  else
    return (<div>-</div>)
}

export default BackArrow;
