import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

import logo from "../../static/logos/placecheck-logo.svg";
import MenuLink from "../shared/MenuLink";
import Toggler from "../shared/Toggler";
import PopupMenu from "./PopupMenu";

const Header = props => {
    const [ isOpen, setIsOpen ] = useState(false);
    const navigate = useNavigate();

    // console.log("Header.js", props)

    const toggle = () => {
        console.log("Toggle navbar");
        setIsOpen(!isOpen);
    }

  return (
    <div className="header">
      <div>
          <img
            src={logo}
            style={{cursor:"pointer"}}
            onClick={() => {navigate(`/maps/${props.slug !== null ? props.slug : ""}`)}}
            alt={`Placecheck logo`} className={`menu-logo`}
          />
      </div>
      {
          isOpen === true && props.mapData !== null
          &&
          <PopupMenu
              isOpen={isOpen}
              userID={props.userID}
              mapData={props.mapData}
              toggle={toggle}
          />
      }
      <Toggler toggle={toggle} />
      <div className={`header-menu`}>
      {
        (props.isAuthenticating === false && props.mapData !== null)
        &&
        <div className="flex">
          {
              props.mapData.map !== undefined
              &&
              (
                (props.mapData.map.hideAbout === null || props.mapData.map.hideAbout === "0")
                &&
                <MenuLink to={"/about"}>
                  About
                </MenuLink>
              )
          }
          {
              props.mapData.map !== undefined
              &&
              (
                (props.mapData.map.hideComments === null || props.mapData.map.hideComments === "0")
                &&
                <MenuLink to={"/comments"}>
                  Comments
                </MenuLink>
              )
          }
          {
              props.mapData.map !== undefined
              &&
              (
                (props.mapData.map.hideLogin === null || props.mapData.map.hideLogin === "0")
                &&
                (
                  props.userID === null
                  ?
                  <React.Fragment>
                    <MenuLink to={"/register"}>
                      Sign up
                    </MenuLink>
                    <MenuLink to={"/login"}>
                      Login
                    </MenuLink>
                  </React.Fragment>
                  :
                  <MenuLink to={"/logout"}>
                    Log out
                  </MenuLink>
                )
            )
          }
        </div>
      }
      </div>
    </div>
  )
}

export default Header;
