import React from 'react';
import getDateString from "../../../utilities/getDateString";

const DateContainer = props => {
    const { dateCreated } = props;
    return (
        <div>
            {getDateString(dateCreated)}
        </div>
    )
}

export default DateContainer;