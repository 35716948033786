import React from 'react';

import '../../css/comments.css';
import Comment from "./Comment";
import { useNavigate } from "react-router-dom";
import BackArrow from "../shared/BackArrow";

const Comments = props => {
  const navigate = useNavigate();

  if (props.mapData === null || props.mapData === undefined) {
    return (
      <div>Loading comments</div>
    )
  }

  const returnToMap = () => {
    navigate(`/maps/${props.slug !== null ? props.slug : ""}`)
  }

  return (
    <div style={{textAlign:"left", padding:"20px"}}>

      <div className={`comments-list`}>
        <div className="d-flex justify-content-start mb-3">
          <div style={{width: "30px"}}>
            <BackArrow action={returnToMap} />
          </div>
          <h3 className="text-center w-100" style={{paddingRight: "30px"}}>
            Comments added to map
          </h3>
        </div>
        {
          props.mapData.markers.map(
            marker => {
              return (
                <Comment
                  key={marker.id}
                  layers={props.mapData.layers}
                  comment={marker}
                />
              )
            }
          )
        }
      </div>
    </div>
  )
}

export default Comments;