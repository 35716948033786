import React from 'react';

import logo from '../../static/logos/placecheck-logo.svg';

const Home = () => {
  return (
    <React.Fragment>
      <div>
        <img src={logo} alt={`Placecheck logo`}/>
      </div>
      <div>
        Welcome to Placecheck. Please enter the URL for the map you want to go to, for example:<br />
        www.placecheck.info/app/maps/<span style={{color: 'red'}}>&lt;map name here&gt;</span>
      </div>
    </React.Fragment>
  )
}

export default Home;