const getLatLngList = geometryDescription => {

  let latlngs = [];
  let coords = geometryDescription.split(" ");

  for (let i = 0; i < coords.length; i++) {
    let coord = coords[i].split(",");
    latlngs.push([coord[0], coord[1]]);
  }

  return latlngs;
}

export default getLatLngList;
