import React from 'react';
import {MapContainer, Marker, Popup, Polygon, ImageOverlay, useMapEvents, ZoomControl} from 'react-leaflet';
import { useParams } from "react-router-dom";

import { defaultMapData } from '../../configuration';

import { v4 as uuidv4 } from 'uuid';

import AddMarkerForm from "./AddMarkerForm";
import ButtonPanel from "./ButtonPanel";
import getIcon from "./utilities/getIcon";
import getLatLngList from "./utilities/getLatLngList";
import { getMaps, getMap, submitQuestionnaire } from "../../utilities/data";
import LoadingMap from "./LoadingMap";
import MapEventHandler from "./MapEventHandler";
import PlacecheckLayersControl from "./PlacecheckLayersControl";
import PopupContent from "./popup-content/PopupContent";
import SignUpPanel from "./SignUpPanel";
import QuestionnairePanel from "../questionnaire/QuestionnairePanel";
import QuestionPanel from "../questionnaire/QuestionPanel";
import QuestionnairePopupContent from "./popup-content/QuestionnairePopupContent";
import QuestionnaireSubmitConfirmation from "../questionnaire/QuestionnaireSubmitConfirmation";
// import LocationButtonPanel from "./LocationButtonPanel";

const withParams = Component => {
  return props => <Component {...props} params={useParams()} />;
}

class MapPage extends React.Component {
  state = {
    lat: null,
    lng: null,
    zoom: null,
    isShowMapCount: false,
    mapType: null,
    addMarkerStage: 0,
    markerLocation: [],
    addMarkerLayer: null,
    addMarkerName: "",
    addMarkerDescription: "",
    mapLocations: [],
    questionnaireSession: null,
    answeredQuestions: [],
    currentQuestionIndex: null,
    questionAnswers: [],
    questionnaireMarkers: [],
    isShowQuestionnaireSubmitConfirmation: false,
    hasSubmitQuestions: false
  }
  componentDidMount = async () => {
    // console.log("MapPage mounted. props: ", this.props)

    if (this.props.params.slug === undefined) {
      await this.loadMapShowingAllPlacechecks();
    }
    else
      await this.loadMapFromSlug();

    await this.setState({questionnaireSession: uuidv4()});
  }
  addQuestionnaireMarker = async markerData => {
    let newQuestionnaireMarkers = [...this.state.questionnaireMarkers];
    newQuestionnaireMarkers.push(markerData);
    await this.setState({questionnaireMarkers: newQuestionnaireMarkers});
  };
  deleteQuestionnaireMarker = async id => {
    let newMarkers = this.state.questionnaireMarkers.filter(marker => marker.id !== id);
    await this.setState({questionnaireMarkers: newMarkers});
  }
  handleMapClick = async e => {
    console.log("Map clicked", e.latlng.lat, e.latlng.lng)

    if (this.props.mapData === null)
      return;

    if (this.props.mapData.map.isOpen === "0")
      return;

    await this.setState({currentQuestionIndex: null});

    if (this.state.addMarkerStage === 0) {
      // Add provisional marker to map
      await this.setState({
        addMarkerStage: 1,
        markerLocation: [e.latlng.lat, e.latlng.lng]
      });
    }

    if (this.state.addMarkerStage === 1) {
      await this.setState({
        markerLocation: [e.latlng.lat, e.latlng.lng]
      })
    }

  }
  handleMapDragEnd = async e => {
    await this.setState({
      markerLocation: [e.target._latlng.lat, e.target._latlng.lng]
    })
  }
  loadMapData = async () => {
    const mapData = await getMap(this.props.params.slug);
    await this.props.updateAppState({
      mapData: mapData.data
    });
    // console.log("MapContainer > loadMapData > mapData", mapData.data)
  }
  loadMapFromSlug = async () => {
    await this.loadMapData();
    await this.setState({
      lat: this.props.mapData.map.startLat,
      lng: this.props.mapData.map.startLng,
      zoom: this.props.mapData.map.startZoom,
      mapType: this.props.mapData.map.mapType,
      isShowMapCount: false
    });
    await this.props.updateAppState({
      isMapLoading: false,
      slug: this.props.params.slug
    });
  }
  loadMapShowingAllPlacechecks = async () => {
    await this.setState({
      lat: defaultMapData.lat,
      lng: defaultMapData.lng,
      zoom: defaultMapData.zoom,
      mapType: defaultMapData.mapType,
    });
    const maps = await getMaps();
    await this.setState({ mapLocations: maps.data, isShowMapCount: true });
    await this.props.updateAppState({ isMapLoading: false })
  }
  updateMapState = async obj => {
    await this.setState(obj);
  }
  sendQuestionnaireResults = async () => {
    const submitQuestionnaireResponse = await submitQuestionnaire(
      this.state.questionnaireSession,
      this.props.mapData.map.id,
      JSON.stringify(this.state.answeredQuestions),
      JSON.stringify(this.state.questionnaireMarkers)
    );
    // console.log("Questionnaire submitted", submitQuestionnaireResponse)
    await this.setState({hasSubmitQuestions: true})
  }
  cancelSubmitQuestionnaire = async () => {
    await this.setState({isShowQuestionnaireSubmitConfirmation: false});
  }
  submitQuestionnaire = async () => {
    await this.setState({isShowQuestionnaireSubmitConfirmation: true});
  }
  render() {
    if (this.props.isMapLoading || this.state.lat === null) {
      return (
        <LoadingMap />
      )
    }

    const { userID, token } = this.props;

    return (
      <div>
        {
          this.state.isShowMapCount === true
          &&
          <div className={`mapCount`}>
            Map count here
          </div>
        }

        {
          (this.props.userID === null && this.state.addMarkerStage === 1)
          &&
          (
            this.props.mapData.map.specialType === null && this.props.mapData.map.hideLogin !== "1"
            &&
            <SignUpPanel />
          )
        }
        {/*{*/}
        {/*  this.props.mapData !== null*/}
        {/*  &&*/}
        {/*  (*/}
        {/*    this.props.mapData.locations.length > 0*/}
        {/*    &&*/}
        {/*    <LocationButtonPanel*/}
        {/*      mapData={this.props.mapData}*/}
        {/*      updateMapState={this.updateMapState}*/}
        {/*    />*/}
        {/*  )*/}
        {/*}*/}
        {
          (this.props.userID !== null && this.props.mapData !== null)
          &&
          (
            this.props.mapData.map.specialType === null
            &&
            <ButtonPanel
              mapState={this.state}
              updateMapState={this.updateMapState}
              mapData={this.props.mapData}
            />
          )
        }
        {
          this.props.mapData !== null
          &&
          (
            this.props.mapData.map.specialType === "questionnaire"
            &&
            <QuestionnairePanel
              mapData={this.props.mapData}
              updateMapState={this.updateMapState}
              answeredQuestions={this.state.answeredQuestions}
              currentQuestionIndex={this.state.currentQuestionIndex}
              submitQuestionnaire={this.submitQuestionnaire}
            />
          )
        }
        {
          this.props.mapData !== null
          &&
          (
            this.state.isShowQuestionnaireSubmitConfirmation === true
            &&
            <QuestionnaireSubmitConfirmation
              questions={this.props.mapData.questions}
              answeredQuestions={this.state.answeredQuestions}
              questionnaireMarkers={this.state.questionnaireMarkers}
              hasSubmitQuestions={this.state.hasSubmitQuestions}
              cancelSubmitQuestionnaire={this.cancelSubmitQuestionnaire}
              sendQuestionnaireResults={this.sendQuestionnaireResults}
            />
          )
        }
        {
          this.props.mapData !== null
          &&
          (
            (this.props.mapData.map.specialType === "questionnaire" && this.state.currentQuestionIndex === null)
            &&
            <ButtonPanel
              mapState={this.state}
              updateMapState={this.updateMapState}
              mapData={this.props.mapData}
            />
          )
        }
        {
          this.state.addMarkerStage === 2
          &&
          <AddMarkerForm
            mapID={this.props.mapData !== null ? this.props.mapData.map.id : null}
            layerID={this.state.addMarkerLayer !== null ? this.state.addMarkerLayer.id : null}
            markerLocation={this.state.markerLocation !== null ? this.state.markerLocation : null}
            mapData={this.props.mapData}
            updateAppState={this.props.updateAppState}
            updateMapState={this.updateMapState}
            loadMapData={this.loadMapData}
            token={this.props.token}
            addQuestionnaireMarker={this.addQuestionnaireMarker}
          />
        }
        {
          this.state.currentQuestionIndex !== null
          &&
          <QuestionPanel
            questions={this.props.mapData.questions}
            forminputs={this.props.mapData.forminputs}
            answeredQuestions={this.state.answeredQuestions}
            updateMapState={this.updateMapState}
            currentQuestionIndex={this.state.currentQuestionIndex}
          />
        }

        <MapContainer
          center={[this.state.lat, this.state.lng]}
          zoom={this.state.zoom}
          className={`map`}
          animate={false}
          zoomControl={false}
          // onClick={this.handleMapClick}
        >
          <MapEventHandler
            mapData={this.props.mapData}
            addMarkerStage={this.state.addMarkerStage}
            updateMapState={this.updateMapState}
          />
          <ZoomControl position="bottomleft" />
          <PlacecheckLayersControl mapType={this.state.mapType} />
          {
            this.props.mapData === null
            ?
              this.state.mapLocations.map(marker => {
                // Render map locations for title screen
                return (
                  <Marker
                    key={marker.id}
                    position={[marker.lat, marker.lng]}
                    icon={getIcon('placecheck')}
                  />
                )
              })
            :
            this.props.mapData.markers.map(marker => {
              // Render markers from active placecheck
              return (
                <Marker
                  key={marker.id}
                  position={[marker.lat, marker.lng]}
                  icon={getIcon(this.props.mapData.layers, marker.layerID, marker.customMarker)}
                >
                  <Popup>
                    {
                      marker.additionalData !== null
                      &&
                      (
                        marker.additionalData.indexOf("survey-question") !== -1
                        ?
                        <QuestionnairePopupContent
                          layers={this.props.mapData.layers}
                          isPhotos={this.props.mapData.map.isPhotos}
                          markerData={marker}
                          userID={userID}
                          token={token}
                          updateMapState={this.updateMapState}
                          loadMapData={this.loadMapData}
                        />
                        :
                        <PopupContent
                          layers={this.props.mapData.layers}
                          isPhotos={this.props.mapData.map.isPhotos}
                          hideVotes={this.props.mapData.map.hideVotes}
                          additionalData={marker.additionalData}
                          additionalMarkerControls={this.props.mapData.map.additionalMarkerControls}
                          markerData={marker}
                          userID={userID}
                          token={token}
                          mapID={this.props.mapData.map.id}
                          loadMapData={this.loadMapData}
                        />
                      )
                    }

                  </Popup>
                </Marker>
              )
            })
          }

          {
            this.state.questionnaireMarkers.map(marker => {
              // Render markers from active placecheck
              return (
                <Marker
                  key={marker.id}
                  position={[marker.lat, marker.lng]}
                  icon={getIcon(this.props.mapData.layers, marker.layerID, marker.customMarker)}
                >
                  <Popup>
                    <PopupContent
                      layers={this.props.mapData.layers}
                      isPhotos={this.props.mapData.map.isPhotos}
                      markerData={marker}
                      userID={userID}
                      token={token}
                      loadMapData={this.loadMapData}
                      isQuestionnaireMarker={true}
                      deleteQuestionnaireMarker={this.deleteQuestionnaireMarker}
                    />
                  </Popup>
                </Marker>
              )
            })

          }

          {
            // Render image overlays if map data exists
            this.props.mapData !== null
            &&
            this.props.mapData.imageoverlays.map(imageoverlay => {
              return (
                <ImageOverlay
                  key={imageoverlay.url}
                  bounds={[ [Number(imageoverlay.topLeftLat),Number(imageoverlay.topLeftLng)], [Number(imageoverlay.bottomRightLat), Number(imageoverlay.bottomRightLng)] ]}
                  url={imageoverlay.url}
                />
              )
            })
          }

          {
            // Render polygons if map data exists
            this.props.mapData !== null
            &&
            this.props.mapData.polygons.map(polygon => {
              return (
                <Polygon
                  key={polygon.id}
                  fill={polygon.isFill === "1"}
                  color={polygon.colourHex}
                  opacity={Number(polygon.opacity)}
                  positions={getLatLngList(polygon.geometryDescription)}
                />
              )
            })
          }
          {
            // Render marker for map pin being added
            this.state.markerLocation.length > 0
            &&
            <Marker
              position={this.state.markerLocation}
              draggable={true}
              ondragend={this.handleMapDragEnd}
              icon={this.state.addMarkerLayer === null ? getIcon(null) : getIcon(this.props.mapData.layers, this.state.addMarkerLayer.id)}
            />
          }
        </MapContainer>
      </div>
    )
  }
}

export default withParams(MapPage);
