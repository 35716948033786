import React from 'react';
import { FaRegThumbsDown, FaRegThumbsUp, FaWrench } from "react-icons/fa";

import iconNatureRetina from "../../../static/images/icon-nature.png";
import iconTransportRetina from "../../../static/images/icon-transport.png";
import iconLocalGreenspaceRetina from "../../../static/images/marker-icon-greenspace.png";
import iconLocalHeritageAssetRetina from "../../../static/images/marker-icon-heritage.png";

const getLayerIcon = props => {
  if (props.defaultMarkerStyleID === "1")
    return <FaRegThumbsUp size={32}/>;
  else if (props.defaultMarkerStyleID === "2")
    return <FaRegThumbsDown size={32}/>;
  else if (props.defaultMarkerStyleID === "3")
    return <FaWrench size={32}/>
  else if (props.defaultMarkerStyleID === "28")
    return <img src={iconNatureRetina} alt="Nature icon" style={{maxHeight:"28px"}} />
  else if (props.defaultMarkerStyleID === "29")
    return <img src={iconTransportRetina} alt="Transport icon" style={{maxHeight:"28px"}} />
  else if (props.defaultMarkerStyleID === "30")
    return <img src={iconLocalGreenspaceRetina} alt="Local Green Space icon" style={{maxHeight:"28px"}} />
  else if (props.defaultMarkerStyleID === "33")
    return <img src={iconLocalHeritageAssetRetina} alt="Local Heritage Asset icon" style={{maxHeight: "28px"}} />
  return '';
}

export default getLayerIcon;
