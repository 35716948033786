import React from 'react';
import openImage from "../../../utilities/openImage";
import AddImagePanel from "./AddImagePanel";
import AdditionalDataInputs from "../AdditionalDataInputs";

class MarkerInfoEdit extends React.Component {
    render() {
        return (
            <React.Fragment>
              <h5 className={`flex-center`}>Edit details and press save</h5>
              <div className={`form-control border-0`}>
                  <div className={`form-label`}>
                      What it is?
                  </div>
                  <div>
                      <input
                          className={`form-input popup-form-input`}
                          name="name"
                          type="text"
                          value={this.props.name}
                          onChange={this.props.updateMarkerPopupState}
                      />
                  </div>
              </div>
              <div className={`form-control border-0`}>
                  <div className={`form-label`}>
                      Why am I adding it?
                  </div>
                  <div>
                      <textarea
                          className={`form-input popup-form-input`}
                          name="description"
                          type="text"
                          value={this.props.description}
                          onChange={this.props.updateMarkerPopupState}
                      />
                  </div>
              </div>
              {/*{*/}
              {/*  this.props.additionalMarkerControls !== null*/}
              {/*  &&*/}
              {/*  <AdditionalDataInputs*/}
              {/*    additionalData={this.props.additionalData}*/}
              {/*    additionalMarkerControls={this.props.additionalMarkerControls}*/}
              {/*    handleAdditionalDataInput={this.props.handleAdditionalDataInput}*/}
              {/*  />*/}
              {/*}*/}
              {
                this.props.isPhotos === true && (
                  (this.props.imagePath === null || this.props.isDeleteImageWhenSavingMarker === true)
                    ?
                    <AddImagePanel
                      updatePopupContentState={this.props.updatePopupContentState}
                      mapID={this.props.mapID}
                    />
                    :
                    <div className={`flex-center`} style={{marginBottom: "7px"}}>
                      <img
                        src={this.props.imagePath}
                        onClick={() => openImage(this.props.imagePath)}
                        className={`popup-image`}
                        alt={this.props.imageName}
                        style={{width: "50%", marginRight: "10px"}}
                      />
                      <div
                        alt={`Remove image button`}
                        className={`popup-button`}
                        onClick={() => this.props.updatePopupContentState({isDeleteImageWhenSavingMarker: true})}
                      >
                        Remove image
                      </div>
                    </div>
                )
              }
            </React.Fragment>
        )
    }
}

export default MarkerInfoEdit;