import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

// import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={`/app`}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// serviceWorker.unregister();
