import React from 'react';

import DateContainer from "./DateContainer";
import VotesContainer from "./VotesContainer";
import MarkerEditButtons from "./MarkerEditButtons";
import MarkerInfoDisplay from "./MarkerInfoDisplay";
import MarkerInfoEdit from "./MarkerInfoEdit";
import VoteButtons from "./VoteButtons";
import {vote, saveMarker, deleteMarker, uploadImage, removeImage} from "../../../utilities/data";
import parseAdditionalData from "../../../utilities/parseAdditionalData";

class PopupContent extends React.Component {
    state = {
        isEditing: false,
        isRequestingDelete: false,
        isSaving: false,
        isVoting: false,
        isDeleting: false,
        name: this.props.markerData.name,
        description: this.props.markerData.description,
        additionalMarkerControls: [],
        additionalData: this.props.markerData.additionalData,
        isDeleteImageWhenSavingMarker: false,
        imageFile: null,
        layerID: this.props.markerData.layerID
    }
    componentDidMount = async () => {
        let additionalMarkerControls = parseAdditionalData(this.props.additionalMarkerControls);
        let additionalData = parseAdditionalData(this.props.additionalData);

      await this.setState({
        additionalMarkerControls: additionalMarkerControls,
        additionalData: additionalData
      })
    }
    deleteMarker = async () => {
        await this.setState({isDeleting: true});
        await deleteMarker(this.props.token, this.props.markerData.id);
        await this.props.loadMapData();
    }
    handleAdditionalDataInput = e => {
      console.log("PopupContent handleAdditionalDataInput", e)
    }
    saveMarker = async () => {
        await this.setState({isSaving: true});

        if (this.state.imageFile !== null)
            await uploadImage(this.props.token, this.props.markerData.id, this.state.imageFile);

        if (this.state.isDeleteImageWhenSavingMarker === true)
            await removeImage(this.props.token, this.props.markerData.id);

        await saveMarker(this.props.token, this.state.name, this.state.description, this.props.markerData);
        await this.props.loadMapData();
        await this.setState({isEditing: false, isSaving: false, isDeleteImageWhenSavingMarker: false});
    }
    toggleIsRequestingDelete = async () => { await this.setState({isRequestingDelete: !this.state.isRequestingDelete}) }
    toggleEdit = async () => {
        await this.setState({
            isEditing: !this.state.isEditing,
            isRequestingDelete: false,
            isSaving: false,
            isVoting: false,
            isDeleting: false,
            isDeleteImageWhenSavingMarker: false
        }); }
    updatePopupContentState = async obj => {
        await this.setState(obj);
    }
    updateMarkerPopupState = async e => {
        let a = {};
        a[e.currentTarget.name] = e.currentTarget.value;
        await this.setState(a);
    }
    sendVote = async value => {
        await this.setState({isVoting: true});
        await vote(this.props.token, this.props.markerData.id, value);
        await this.props.loadMapData();
        await this.setState({isVoting: false});
    }
    render() {
        const { markerData } = this.props;
        const isOwnMarker = Number(markerData.ownerID) === Number(this.props.userID);

        return (
            <div className={`popup-container`}>
                {
                    this.state.isEditing
                    ?
                    <MarkerInfoEdit
                        name={this.state.name}
                        description={this.state.description}
                        additionalMarkerControls={this.state.additionalMarkerControls}
                        additionalData={this.state.additionalData}
                        handleAdditionalDataInput={this.handleAdditionalDataInput}
                        isPhotos={this.props.isPhotos === "1"}
                        imagePath={markerData.imagePath}
                        imageName={markerData.imageName}
                        isDeleteImageWhenSavingMarker={this.state.isDeleteImageWhenSavingMarker}
                        updateMarkerPopupState={this.updateMarkerPopupState}
                        updatePopupContentState={this.updatePopupContentState}
                        mapID={this.props.mapID}
                    />
                    :
                    <React.Fragment>
                        <MarkerInfoDisplay
                            markerData={markerData}
                        />
                        {
                            this.props.isQuestionnaireMarker === true
                            &&
                            <React.Fragment>
                                <span style={{color:"red"}}>
                                    Thank you for adding this comment.
                                    Please note this will not be saved until you submit your answers to the questionnaire.
                                </span>
                                <div className="flex-center">
                                    <button type="button" onClick={() => this.props.deleteQuestionnaireMarker(markerData.id)}>
                                        Delete this map pin
                                    </button>
                                </div>
                            </React.Fragment>
                        }
                    </React.Fragment>

                }
                {
                    this.props.isQuestionnaireMarker !== true
                    &&
                    (
                      <div className={`flex`}>
                        {this.props.hideVotes !== "1" && <VotesContainer upvotes={markerData.upvotes} downvotes={markerData.downvotes}/>}
                        <DateContainer dateCreated={markerData.dateCreated}/>
                      </div>
                    )
                }
                <div className={`flex marker-actions-container`}>
                    {
                        this.props.isQuestionnaireMarker !== true && this.props.hideVotes !== "1"
                        &&
                        (
                            (isOwnMarker === true && this.props.userID !== null)
                            ?
                            <MarkerEditButtons
                                toggleEdit={this.toggleEdit}
                                isEditing={this.state.isEditing}
                                isSaving={this.state.isSaving}
                                isDeleting={this.state.isDeleting}
                                saveMarker={this.saveMarker}
                                toggleIsRequestingDelete={this.toggleIsRequestingDelete}
                                isRequestingDelete={this.state.isRequestingDelete}
                                deleteMarker={this.deleteMarker}
                            />
                            :
                            <VoteButtons
                                userID={this.props.userID}
                                sendVote={this.sendVote}
                                votes={markerData.votes}
                                isVoting={this.state.isVoting}
                            />
                        )
                    }
                </div>

            </div>
        )
    }
}

export default PopupContent;