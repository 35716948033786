const getLayerButtonLabel = layer => {

  let name = layer.name;
  if (name === 'Good')
    name = 'something I like';
  else if (name === 'Bad')
    name = "something I don't like"
  else
    name = name.charAt(0).toLowerCase() + name.slice(1);

  return `Add ${name}`;
}

export default getLayerButtonLabel;
