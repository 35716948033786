const getCurrentVote = (userID, votes) => {
    if (votes === undefined)
        return 0;
    for (let i = 0; i < votes.length; i++) {
        if (Number(votes[i].OwnerID) === Number(userID))
            return Number(votes[i].Vote);
    }
    return 0;
}

export default getCurrentVote;