import React from 'react';

const QuestionnairePanel = props => {

  const showQuestion = async index => {
    await props.updateMapState({
      currentQuestionIndex: index
    });
  }

  const finishQuestions = async () => {
    props.submitQuestionnaire();
  }

  return (
    <div className={`flex-center`}>
      <div className={`questionnaire-info-panel`}>
        <h2>{props.mapData.map.name}</h2>
        {
          props.currentQuestionIndex === null
          &&
          <div className="questions-answered">
            {props.answeredQuestions.length}/{props.mapData.questions.length} questions answered
          </div>
        }
        {
          props.currentQuestionIndex === null && props.answeredQuestions.length > 0
          &&
          <div className="button button-finish btn-sm" onClick={() => finishQuestions()}>Finish survey</div>
        }
        {
          props.currentQuestionIndex !== null
          &&
          <div className="flex">
            <div className="button btn-sm" onClick={() => showQuestion(props.currentQuestionIndex - 1)} style={{visibility: props.currentQuestionIndex === 0 ? 'hidden' : 'visible'}}>
              Previous
            </div>
            <div className="button btn-sm" onClick={() => showQuestion(null)}>
              Return to map
            </div>
            <div className="button btn-sm" onClick={() => showQuestion(props.currentQuestionIndex + 1)} style={{visibility: props.currentQuestionIndex === (props.mapData.questions.length - 1) ? 'hidden' : 'visible'}}>
              Next
            </div>
          </div>
        }

      </div>
    </div>
  )
}

export default QuestionnairePanel;