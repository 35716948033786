import React from 'react';
import parse from "html-react-parser";

import openImage from "../../../utilities/openImage";


const MarkerInfoDisplay = props => {
    const { markerData } = props;
    const parsedDescription = parse(markerData.description);

    return (
        <React.Fragment>
            <h3>{markerData.name}</h3>
            {
                markerData.imagePath !== null
                &&
                <img
                    src={markerData.imagePath}
                    onClick={() => openImage(markerData.imagePath)}
                    className={`popup-image`}
                    alt={markerData.imageName}
                />
            }
            <div className="marker-info-description">{parsedDescription}</div>
        </React.Fragment>
    )
}

export default MarkerInfoDisplay;