import React from 'react';
import { BingLayer } from "react-leaflet-bing-v2";
import { bing_key } from "../../configuration";
import { LayersControl, TileLayer} from "react-leaflet";

const { BaseLayer } = LayersControl;

const PlacecheckLayersControl = props => {
    return (
        <LayersControl position='bottomright' style={{textAlign:"left"}}>
            <BaseLayer style={{textAlign:"left"}} checked={props.mapType === "BING"} name='Road map'>
                <BingLayer bingkey={bing_key} type="Road"/>
            </BaseLayer>
            <BaseLayer checked={props.mapType === "BINGAERIAL"} name='Aerial'>
                <BingLayer bingkey={bing_key} />
            </BaseLayer>
            <BaseLayer name='Hybrid'>
                <BingLayer bingkey={bing_key} type="AerialWithLabels" />
            </BaseLayer>
            <BaseLayer checked={props.mapType === "OSM"} name='OSM'>
                <TileLayer
                    attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                    url="https://api.maptiler.com/maps/openstreetmap/256/{z}/{x}/{y}.jpg?key=ylUyypBJAHqAitSxyHOS"
                />
            </BaseLayer>
            <BaseLayer checked={props.mapType === "OS"} name='OS Zoomstack (UK)'>
              <TileLayer
                attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a>, Contains OS data © Crown copyright and database right 2019'
                url="https://api.maptiler.com/maps/uk-openzoomstack-light/256/{z}/{x}/{y}.png?key=ylUyypBJAHqAitSxyHOS"
              />
            </BaseLayer>
            <BaseLayer checked={props.mapType === "OSOUTDOOR"} name='OS Zoomstack Outdoor (UK)'>
              <TileLayer
                attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a>, Contains OS data © Crown copyright and database right 2019'
                url="https://api.maptiler.com/maps/uk-openzoomstack-outdoor/256/{z}/{x}/{y}.png?key=ylUyypBJAHqAitSxyHOS"
              />
            </BaseLayer>
            <BaseLayer checked={props.mapType === "TONER"} name='Toner (High contrast)'>
              <TileLayer
                attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                url="https://api.maptiler.com/maps/toner/256/{z}/{x}/{y}.png?key=ylUyypBJAHqAitSxyHOS"
              />
            </BaseLayer>
        </LayersControl>
    )
}

export default PlacecheckLayersControl;