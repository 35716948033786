import React from 'react';
import { Link } from 'react-router-dom';

const MenuLink = props => {
  return (
    <Link className={`menuLink`} to={props.to}>
      {props.children}
    </Link>
  )
}

export default MenuLink;
