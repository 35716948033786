import axios from 'axios';
import { apiURL } from "../configuration";

export const authenticate = async (username, password) => {
  // console.log("Authenticate", username, password)
  try {
    const response = await axios({
      method: "post",
      mode: "no-cors",
      url: `${apiURL}/authenticate`,
      data: {username: username, password: password}
    });
    // console.log("Response: ", response)
    return response;
  } catch (e) {
    console.log("Sorry something went wrong");
    console.log(e);
  }
};

export const authenticateToken = async (token) => {
  try {
    const response = await axios({
      method: "post",
      mode: "no-cors",
      url: `${apiURL}/authenticateToken`,
      data: {token: token}
    });
    return response;
  } catch (e) {
    console.log("Token not authenticated - this may have expired. Please try logging in again.");
    console.log(e);
  }
}

export const logout = async (token) => {
  try {
    const response = await axios({
      method: "post",
      mode: "no-cors",
      url: `${apiURL}/logout`,
      data: {token: token}
    });
    return response;
  } catch (e) {
    console.log("Sorry something went wrong");
    console.log(e);
  }
}

export const requestPasswordReset = async email => {
  try {
    const response = await axios({
      method: "post",
      mode: "no-cors",
      url: `${apiURL}/requestPasswordReset`,
      data: {email: email}
    })
    return response;
  } catch (e) {
    console.log("Sorry something went wrong");
    console.log(e);
  }
}

export const resetPassword = async (password, token) => {
  try {
    const response = await axios({
      method: "post",
      mode: "no-cors",
      url: `${apiURL}/resetPassword`,
      data: {
        password: password,
        token: token
      }
    })

    if (response.data.response === 200) {
      // console.log("Success!")
    } else {
      console.log("Problem");
    }
    return response;
  } catch (e) {
    console.log("Sorry something went wrong");
    console.log(e);
  }
}

export const register = async (data) => {
  // console.log("Should be registering new user", data)
  try {
    const registerResponse = await axios({
      method: "post",
      mode: "no-cors",
      url: `${apiURL}/register`,
      data: {
        username: data.username,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName
      }
    })
    return registerResponse;
  } catch (e) {
    // console.log(e);
    // console.log("Error registering user");
  }
}

export const getMap = async (slug) => {
  const mapData = await axios({
    method: "post",
    mode: "no-cors",
    url: `${apiURL}/map`,
    data: {
      slug: slug
    }
  })
  return mapData;
}

export const getMaps = async (slug) => {
  const mapData = await axios({
    method: "post",
    mode: "no-cors",
    url: `${apiURL}/maps`,
    data: {
      slug: slug
    }
  })
  return mapData;
}

export const addMarker = async (markerData) => {
  const addMarkerResponse = await axios({
    method: "post",
    mode: "no-cors",
    url: `${apiURL}/marker/add`,
    data: {
      "mapID": markerData.mapID,
      "layerID": markerData.layerID,
      "token": markerData.token,
      "title": markerData.title,
      "description": markerData.description,
      "lat" : markerData.markerLocation[0],
      "lng": markerData.markerLocation[1],
      "additionalData": JSON.stringify(markerData.additionalData)
    }
  });
  return addMarkerResponse;
}

export const saveMarker = async (token, name, description, markerData) => {
  const saveMarkerResponse = await axios({
    method: "post",
    mode: "no-cors",
    url: `${apiURL}/marker/edit`,
    data: {
      "token": token,
      "layerID": markerData.layerID,
      "title": name,
      "description": description,
      "lat": markerData.lat,
      "lng": markerData.lng,
      "id": markerData.id
    }
  });
  return saveMarkerResponse;
}

export const deleteMarker = async (token, id) => {
  const deleteMarkerResponse = await axios({
    method: "post",
    mode: "no-cors",
    url: `${apiURL}/marker/delete`,
    data: {
      "token": token,
      "id": id
    }
  });
  return deleteMarkerResponse;
}

export const removeImage = async (token, markerID) => {
  const removeImageResponse = await axios({
    method: "post",
    mode: "no-cors",
    url: `${apiURL}/image/remove`,
    data: {
      "token": token,
      "markerID": markerID
    }
  })
  return removeImageResponse;
}

export const uploadImage = async (token, markerID, file) => {
    let formData = new FormData();
    formData.append("token", token);
    formData.append("markerID", markerID);
    formData.append("imageFile", file);

    const uploadImageResponse = await axios({
      method: "post",
      mode: "no-cors",
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: `${apiURL}/image/upload`,
      data: formData
    })
  return uploadImageResponse;
}

export const vote = async (token, markerID, vote) => {
  const voteResponse = await axios({
    method: "post",
    mode: "no-cors",
    url: `${apiURL}/marker/vote`,
    data: {
      "token": token,
      "markerID": markerID,
      "vote": vote
    }
  })
  return voteResponse;
}

export const getAccountData = async (token) => {
  const accountDataResponse = await axios({
    method: "post",
    mode: "no-cors",
    url: `${apiURL}/account`,
    data: {
      "token": token
    }
  });
  return accountDataResponse;
}

export const submitQuestionnaire = async (sessionID, mapID, answeredQuestions, questionnaireMarkers) => {
  const submitQuestionnaireResponse = await axios({
    method: "post",
    mode: "no-cors",
    url: `${apiURL}/questionnaire/submit`,
    data: {
      "sessionID": sessionID,
      "mapID": mapID,
      "answeredQuestions": answeredQuestions,
      "questionnaireMarkers": questionnaireMarkers
    }
  });
  return submitQuestionnaireResponse;
}