import React from 'react';
import parse from "html-react-parser";

import placecheckLogo from '../static/logos/placecheck-logo.svg';
import BackArrow from "./shared/BackArrow";
import {useNavigate} from "react-router-dom";

const About = props => {

    const navigate = useNavigate();

    if (props.mapData === null) {
        return (
            <div>
                <img src={placecheckLogo} alt={`Placecheck logo`} className={`main-logo`} />
                <p>
                    To access your Placecheck map please go to the link you have been given.
                </p>
                <p>
                    It will be in the format <strong>https://www.placecheck.info/app/maps/&#123;Your Map Name Here&#125;</strong>
                </p>
                <p>
                    For technical assistance please contact <a href={`mailto:admin@placecheck.info`}>admin@placecheck.info</a>
                </p>
            </div>
        )
    }

    const howToPlacecheckContent = (
      <React.Fragment>
        <h3>How to Placecheck</h3>
        <ol>
          <li>Sign up by clicking the link at the top-right of the screen (if you have not already done so).</li>
          <li>Log in. This will make the Placecheck buttons appear.</li>
          <li>Use the symbol on the right-hand side of te screen to select the style of base map you would like.</li>
          <li>Click on the button for the type of marker you would like to add - the green button for ‘Add something I like’, the red button for ‘Add something I don’t like’, and the orange button for ‘Something we need to work on’.</li>
          <li>Click on the map where you want to make a comment.</li>
          <li>Click on ‘Save to map’.</li>
          <li>In the box that opens, write down what it is (such as 'Park gates' or 'Litter' and why you are adding it.</li>
            {
                Number(props.mapData.map.isPhotos) === 1
                &&
                <li>Choose a photo to add. On a mobile this will go straight to camera. (You can use a computer to edit a map pin and upload a pre-existing photo later if you prefer.)</li>
            }
          <li>Click on Save.</li>
          <li>You can return to your map pin later to edit or delete it, but you cannot edit or delete a map pin that someone else has added.</li>
        </ol>
        <p>
          You can return to your marker later to edit or delete it, but you can not edit or delete a marker that someone else has posted.
          You can click to agree or disagree with someone else's comment.
        </p>
      </React.Fragment>
    )

    //console.log("About", props.mapData)

    return (
      <div style={{textAlign:"left", padding:"20px"}}>

          <div className="d-flex justify-content-start">
              <div style={{width: "30px"}}>
                  <BackArrow action={() => navigate(`/maps/${props.mapData.map.directory}`)} />
              </div>
              <h3 className="text-center w-100" style={{paddingRight: "30px"}}>
                  {props.mapData.map.name}
              </h3>
          </div>

          <div className="my-4">
              {parse(props.mapData.map.description)}
          </div>

        {
            (props.mapData.map.hideHowToPlacecheck === "0" || props.mapData.map.hideHowToPlacecheck === null) && howToPlacecheckContent
        }
      </div>
    )

}

export default About;
