import {colours} from "../../../configuration";

const getLayerButtonColour = data => {
  if (data.defaultMarkerStyleID === "1")
    return colours.good;
  else if (data.defaultMarkerStyleID === "2")
    return colours.bad;
  else if (data.defaultMarkerStyleID === "3")
    return colours.improvement;
  else if (data.defaultMarkerStyleID === "28")
    return colours.nature;
  else if (data.defaultMarkerStyleID === "29")
    return colours.transport;
  else if (data.defaultMarkerStyleID === "30")
    return colours.nature;
  else if (data.defaultMarkerStyleID === "31")
    return colours.nature;
  else if (data.defaultMarkerStyleID === "32")
    return colours.transport;
  else if (data.defaultMarkerStyleID === "33")
    return colours.heritage;
  return colours["placecheck-blue"];
  // return '#cdcdcd';
}

export default getLayerButtonColour;
