import React from 'react';
import { Link } from "react-router-dom";

import { logout } from "../../utilities/data";

class Logout extends React.Component {
  componentDidMount = async () => {
    await logout(this.props.token);
    this.props.updateAppState({
      userID: null,
      token: ""
    })
    this.props.removeCookie();
    // console.log("Logout > slug", this.props.slug)
  }
  render() {
    return (
      <div>
        <div style={{marginTop:"30px"}}>
          <p>
            You are now logged out of Placecheck.
          </p>
          <p>
            You can still view the map and read comments, but you need to log in again to participate in the Placecheck.
          </p>
        </div>
        <div style={{marginTop:"30px"}}>
          <Link className={`button`} to={`/maps/${this.props.slug !== null ? this.props.slug : ""}`}>
            Continue
          </Link>
        </div>
      </div>
    )
  }
}

export default Logout;
