import React from 'react';
import {useMapEvents} from "react-leaflet";

const MapEventHandler = props => {

  useMapEvents({
    click: e => {
      if (props.mapData === null)
        return;

      if (props.mapData.map.isOpen === "0")
        return;

      if (props.addMarkerStage === 0) {
        props.updateMapState({
          addMarkerStage: 1,
          markerLocation: [e.latlng.lat, e.latlng.lng]
        })
      }

      if (props.addMarkerStage === 1) {
        props.updateMapState({
          markerLocation: [e.latlng.lat, e.latlng.lng]
        })
      }

    }
  })
  return null;
}

export default MapEventHandler;