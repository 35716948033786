import React from 'react';
import MenuLink from "../shared/MenuLink";

const PopupMenu = props => {
    return (
        <div className={`popup-menu`} onClick={props.toggle}>
            {
                props.mapData.map !== undefined
                &&
                (
                    (props.mapData.map.hideAbout === null || props.mapData.map.hideAbout === "0")
                    &&
                    <MenuLink to={"/about"}>
                        About
                    </MenuLink>
                )
            }
            {
                props.mapData.map !== undefined
                &&
                (
                    (props.mapData.map.hideComments === null || props.mapData.map.hideComments === "0")
                    &&
                    <MenuLink to={"/comments"}>
                        Comments
                    </MenuLink>
                )
            }
            {
                props.mapData.map !== undefined
                &&
                (
                    (props.mapData.map.hideLogin === null || props.mapData.map.hideLogin === "0")
                    &&
                    (
                        props.userID === null
                            ?
                            <React.Fragment>
                                <MenuLink to={"/register"}>
                                    Sign up
                                </MenuLink>
                                <MenuLink to={"/login"}>
                                    Login
                                </MenuLink>
                            </React.Fragment>
                            :
                            <MenuLink to={"/logout"}>
                                Log out
                            </MenuLink>
                    )
                )
            }
        </div>
    )
}

export default PopupMenu;