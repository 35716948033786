import React from 'react';
import { Link } from 'react-router-dom';

import getCurrentVote from "../utilities/getCurrentVote";

const VoteButtons = props => {

    if (props.userID === null) {
        return (
            <div>
                <small>
                    <Link to={`/login`}>Login</Link> or <Link to={`/register`}>sign up</Link> to participate in this Placecheck
                </small>
            </div>
        )
    }

    let currentVote = getCurrentVote(props.userID, props.votes);

    if (currentVote === 0) {
        return (
            <React.Fragment>
                <div className={`flex`}>
                    <div className={`popup-button`} onClick={() => props.sendVote(1)}>Agree</div>
                    <div className={`popup-button`} onClick={() => props.sendVote(-1)}>Disagree</div>
                </div>
                <div style={{marginLeft:"10px"}}>
                    {props.isVoting && `Sending`}
                </div>
            </React.Fragment>
        )
    }

    return (
        <div className={`flex`} style={{width: "100%"}}>
            <div>
                {currentVote === 1 && `You agree`}
                {currentVote === -1 && `You disagree`}
            </div>
            <div className={`popup-button`} onClick={() => props.sendVote(0)}>
                {props.isVoting ? `Removing` : `Remove vote`}
            </div>
        </div>

    )
}

export default VoteButtons;