import React, {useEffect, useState} from 'react';
import parse from "html-react-parser";
import RadioGroup from "./questions/RadioGroup";
import TextInput from "./questions/TextInput";

const QuestionPanel = props => {
  const [forminputs, setForminputs] = useState([]);

  const getFormInputs = id => {
    return props.forminputs.filter(forminput => {
      if (forminput.questionID === id)
        return true;
    })
  }

  useEffect(() => {
    // console.log("Opening QuestionPanel");
    let questionforminputs = getFormInputs(props.questions[props.currentQuestionIndex].id);
    setForminputs(questionforminputs);
    // console.log("Form inputs are ", questionforminputs);
  }, [props.currentQuestionIndex])

  const close = () => { props.updateMapState({currentQuestionIndex: null})};
  const next = () => { props.updateMapState({currentQuestionIndex: (props.currentQuestionIndex + 1)})}
  const prev = () => { props.updateMapState({currentQuestionIndex: (props.currentQuestionIndex - 1)})}

  const NavigationPanel = () => {
    return (
      <div className="flex" style={{marginTop:"10px", flexWrap:"wrap", flexBasis:"180px"}}>
        <div className="button" onClick={prev} style={{visibility: props.currentQuestionIndex === 0 ? 'hidden' : 'visible'}}>
          Previous
        </div>
        <div className="button" onClick={close}>
          Return to map
        </div>
        <div className="button" onClick={next} style={{visibility: props.currentQuestionIndex === (props.questions.length - 1) ? 'hidden' : 'visible'}}>
          Next
        </div>
      </div>
    )
  }

  return (
    <div className="question-panel">
      <h2 style={{paddingTop:"40px"}}>{props.questions[props.currentQuestionIndex].title}</h2>
      {parse(props.questions[props.currentQuestionIndex].content)}
      {
        forminputs.map(forminput => {
          return (
            <React.Fragment key={forminput.id}>
              {
                forminput.type === "radio"
                &&
                <RadioGroup
                  forminput={forminput}
                  updateMapState={props.updateMapState}
                  answeredQuestions={props.answeredQuestions}
                />
              }
              {
                forminput.type === "text"
                &&
                <TextInput
                  forminput={forminput}
                  updateMapState={props.updateMapState}
                  answeredQuestions={props.answeredQuestions}
                />
              }
            </React.Fragment>
          )
        })
      }
      <NavigationPanel />
    </div>
  )
}

export default QuestionPanel;