import { Icon } from 'leaflet';
import iconGood from "../../../static/images/marker-icon-good.png";
import iconGoodRetina from "../../../static/images/marker-icon-good-2x.png";
import iconBad from "../../../static/images/marker-icon-bad.png";
import iconBadRetina from "../../../static/images/marker-icon-bad-2x.png";
import iconImprovement from "../../../static/images/marker-icon-improvement.png";
import iconImprovementRetina from "../../../static/images/marker-icon-improvement-2x.png";

import iconPlaque from "../../../static/images/marker-plaque.png";
import iconPlaqueRetina from "../../../static/images/marker-plaque-2x.png";

import iconNature from "../../../static/images/marker-icon-nature.png";
import iconNatureRetina from "../../../static/images/marker-icon-nature-2x.png";
import iconTransport from "../../../static/images/marker-icon-transport.png";
import iconTransportRetina from "../../../static/images/marker-icon-transport-2x.png";
import iconLocalGreenspace from "../../../static/images/marker-icon-greenspace.png";
import iconLocalGreenspaceRetina from "../../../static/images/marker-icon-greenspace-2x.png";
import iconView from "../../../static/images/marker-icon-view.png";
import iconViewRetina from "../../../static/images/marker-icon-view-2x.png";
import iconLocalHeritageAsset from "../../../static/images/marker-icon-heritage.png";
import iconLocalHeritageAssetRetina from "../../../static/images/marker-icon-heritage-2x.png";

import iconGrey from "../../../static/images/marker-icon-bw.png";
import iconGreyRetina from "../../../static/images/marker-icon-bw-2x.png";
import iconWhite from "../../../static/images/marker-icon-white.png";
import iconWhiteRetina from "../../../static/images/marker-icon-white-2x.png";
import iconQuestionOne from "../../../static/images/question-icon-1.png";
import iconQuestionOneRetina from "../../../static/images/question-icon-1-2x.png";
import iconQuestionTwo from "../../../static/images/question-icon-2.png";
import iconQuestionTwoRetina from "../../../static/images/question-icon-2-2x.png";
import iconQuestionThree from "../../../static/images/question-icon-3.png";
import iconQuestionThreeRetina from "../../../static/images/question-icon-3-2x.png";
import iconQuestionFour from "../../../static/images/question-icon-4.png";
import iconQuestionFourRetina from "../../../static/images/question-icon-4-2x.png";
import iconQuestionFive from "../../../static/images/question-icon-5.png";
import iconQuestionFiveRetina from "../../../static/images/question-icon-5-2x.png";
import iconQuestionSix from "../../../static/images/question-icon-6.png";
import iconQuestionSixRetina from "../../../static/images/question-icon-6-2x.png";
import iconQuestionSeven from "../../../static/images/question-icon-7.png";
import iconQuestionSevenRetina from "../../../static/images/question-icon-7-2x.png";
import iconQuestionEight from "../../../static/images/question-icon-8.png";
import iconQuestionEightRetina from "../../../static/images/question-icon-8-2x.png";
import iconQuestionNine from "../../../static/images/question-icon-9.png";
import iconQuestionNineRetina from "../../../static/images/question-icon-9-2x.png";
import iconQuestionTen from "../../../static/images/question-icon-10.png";
import iconQuestionTenRetina from "../../../static/images/question-icon-10-2x.png";

import placecheckIcon from "../../../static/images/placecheck.png";

const iconNames = {
  "marker-icon-good.png": [iconGood, iconGoodRetina],
  "marker-icon-bad.png": [iconBad, iconBadRetina],
  "marker-icon-improvement.png": [iconImprovement, iconImprovementRetina],
  "marker-plaque.png": [iconPlaque, iconPlaqueRetina],
  "marker-icon-nature.png": [iconNature, iconNatureRetina],
  "marker-icon-transport.png": [iconTransport, iconTransportRetina],
  "marker-icon-greenspace.png": [iconLocalGreenspace, iconLocalGreenspaceRetina],
  "marker-icon-heritage.png": [iconLocalHeritageAsset, iconLocalHeritageAssetRetina],
  "marker-icon-view.png": [iconView, iconViewRetina],
  "question-icon-1.png": [iconQuestionOne, iconQuestionOneRetina],
  "question-icon-2.png": [iconQuestionTwo, iconQuestionTwoRetina],
  "question-icon-3.png": [iconQuestionThree, iconQuestionThreeRetina],
  "question-icon-4.png": [iconQuestionFour, iconQuestionFourRetina],
  "question-icon-5.png": [iconQuestionFive, iconQuestionFiveRetina],
  "question-icon-6.png": [iconQuestionSix, iconQuestionSixRetina],
  "question-icon-7.png": [iconQuestionSeven, iconQuestionSevenRetina],
  "question-icon-8.png": [iconQuestionEight, iconQuestionEightRetina],
  "question-icon-9.png": [iconQuestionNine, iconQuestionNineRetina],
  "question-icon-10.png": [iconQuestionTen, iconQuestionTenRetina],
}

const getIcon = (layers, layerID, customMarker) => {

  if (layers === 'placecheck') {
    return new Icon({
      iconUrl: placecheckIcon,
      iconURLRetina: placecheckIcon,
      iconSize: [50,50],
      iconAnchor: [25,25]
    })
  }

  if (layers === null) {
    return new Icon({
      iconUrl: iconWhite,
      iconURLRetina: iconWhiteRetina,
      iconSize: [25,41],
      iconAnchor: [13,35]
    })
  }

  let iconURL = iconGrey;
  let iconURLRetina = iconGreyRetina;

  let layerData = {};
  for (let i = 0; i < layers.length; i++) {
    if (layers[i].id === layerID) {
      layerData = layers[i];
      iconURL = iconNames[layers[i].iconUrl][0];
      iconURLRetina = iconNames[layers[i].iconUrl][1];
    }
  }

  if (layerData.defaultMarkerStyleID === "2")
    iconURL = iconBad;
  if (layerData.defaultMarkerStyleID === "3")
    iconURL = iconImprovement;
  if (layerData.defaultMarkerStyleID === "28")
    iconURL = iconNature;
  if (layerData.defaultMarkerStyleID === "29")
    iconURL = iconTransport;
  if (layerData.defaultMarkerStyleID === "30")
    iconURL = iconLocalGreenspace;
  if (layerData.defaultMarkerStyleID === "33")
    iconURL = iconLocalHeritageAsset;

  let iconSize = [Number(layerData.iconWidth),Number(layerData.iconHeight)];
  let iconAnchor = [Number(layerData.iconAnchorX),Number(layerData.iconAnchorY)];

  if(customMarker !== undefined && customMarker !== null) {
    iconURL = iconNames[customMarker.IconUrl][0];
    iconURLRetina = iconNames[customMarker.IconUrl][1];
    iconSize = [Number(customMarker.IconWidth), Number(customMarker.iconHeight)];
    iconAnchor = [Number(customMarker.IconAnchorX), Number(customMarker.IconAnchorY)];
  }

  return new Icon({
    iconUrl: iconURL,
    iconURLRetina: iconURLRetina,
    iconSize: iconSize,
    iconAnchor: iconAnchor
  })
}

export default getIcon;
