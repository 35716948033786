// export const apiURL = 'https://www.placecheck.info/api/api';
export const apiURL = 'https://www.placecheck.info/api-dev/api';
//export const apiURL = 'http://localhost:8080';

export const bing_key = "AnKq37SIxlq6EUtEVmTTjqXtz7rkl0XXk4YuyTbkC6nGZ1Vt49D3NXE86qvP0wui";

export const cookieName = 'placecheck';

export const colours = {
  "good": "#5cb85c",
  "bad": "#d9534f",
  "improvement": "#f0ad4e",
  "placecheck-blue": "#00146d",
  "placecheck-blue-medium": "#0078a8",
  "placecheck-blue-light": "#72c4c0",
  "nature": "#5cb85c",
  "transport": "#2792ca",
  "heritage": "#a57520"
}

export const defaultMapData = {
  lat: 51.507940,
  lng: -0.128018,
  zoom: 5,
  mapType: 'BINGAERIAL'
}

export const defaultLabels = {
  name: 'What it is?',
  description: 'Why am I adding it?'
}

export const customNameDescriptionLabels = [
  {
    layerID: 1363,
    name: 'Name of green space',
    description: 'Why am I adding it?'
  },
  {
    layerID: 1374,
    name: 'Name of green space',
    description: 'Why am I adding it?'
  },
  {
    layerID: 1375,
    name: 'Name and direction of view',
    description: 'Why am I adding it?'
  }
]