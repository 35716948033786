import React from 'react';

const AdditionalDataInputs = props => {
  if (props.additionalMarkerControls.length === 0)
    return null;

  const additionalInputs = [];

  props.additionalMarkerControls.forEach(control => {
    if (control.type === 'checkbox') {
      additionalInputs.push(
        <div className="form-control border-0" key={control.id}>
          <div className="form-check">
            <input
              onClick={props.handleAdditionalDataInput}
              name={control.id}
              className="form-check-input"
              type="checkbox"
              value=""
              id={control.id}
            />
            <label className="form-check-label" htmlFor={control.id}>
              {control.label}
            </label>
          </div>
        </div>
      )
    }
    else if (control.type === 'title') {
      additionalInputs.push(
        <div className="form-control border-0" key={control.id}>
          <h5 className="text-center">{control.label}</h5>
        </div>
      )
    }
  })

  return (
    <React.Fragment>
      {
        additionalInputs.map(input => input)
      }
    </React.Fragment>
  )
}

export default AdditionalDataInputs;