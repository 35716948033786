import React from 'react';

import MarkerInfoEdit from "./MarkerInfoEdit";
import { deleteMarker } from "../../../utilities/data";

class PopupContent extends React.Component {
    state = {
        isEditing: false,
        isRequestingDelete: false,
        isSaving: false,
        isVoting: false,
        isDeleting: false,
        name: this.props.markerData.name,
        description: this.props.markerData.description,
        isDeleteImageWhenSavingMarker: false,
        imageFile: null,
        layerID: this.props.markerData.layerID
    }
    deleteMarker = async () => {
        await this.setState({isDeleting: true});
        await deleteMarker(this.props.token, this.props.markerData.id);
        await this.props.loadMapData();
    }
    saveMarker = async () => {
        alert ("This should be saving marker to local state")
        await this.setState({isSaving: true});
        //await saveMarker(this.props.token, this.state.name, this.state.description, this.props.markerData);
        //await this.props.loadMapData();
        await this.setState({isEditing: false, isSaving: false, isDeleteImageWhenSavingMarker: false});
    }
    toggleIsRequestingDelete = async () => { await this.setState({isRequestingDelete: !this.state.isRequestingDelete}) }
    toggleEdit = async () => {
        await this.setState({
            isEditing: !this.state.isEditing,
            isRequestingDelete: false,
            isSaving: false,
            isVoting: false,
            isDeleting: false,
            isDeleteImageWhenSavingMarker: false
        }); }
    updatePopupContentState = async obj => {
        await this.setState(obj);
    }
    updateMarkerPopupState = async e => {
        let a = {};
        a[e.currentTarget.name] = e.currentTarget.value;
        await this.setState(a);
    }

    render() {
        const { markerData } = this.props;

        const questionNumber = markerData.additionalData.split('-')[2];

        const setQuestion = () => { this.props.updateMapState({currentQuestionIndex: questionNumber - 1}); };

        return (
            <div className={`popup-container`}>
                {
                    this.state.isEditing
                    ?
                    <MarkerInfoEdit
                        name={this.state.name}
                        description={this.state.description}
                        isPhotos={this.props.isPhotos === "1"}
                        imagePath={markerData.imagePath}
                        imageName={markerData.imageName}
                        isDeleteImageWhenSavingMarker={this.state.isDeleteImageWhenSavingMarker}
                        updateMarkerPopupState={this.updateMarkerPopupState}
                        updatePopupContentState={this.updatePopupContentState}
                    />
                    :
                  <div style={{display:"flex", flexDirection:"column", justifyContent: "center", alignItems: "center"}}>
                      <h3>Question {questionNumber} - {markerData.description}</h3>
                      <div className="button btn-sm" onClick={setQuestion}>Go to question</div>
                  </div>
                }
            </div>
        )
    }
}

export default PopupContent;