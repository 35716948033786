import iconGoodRetina from "../../../static/images/marker-icon-good-2x.png";
import iconBadRetina from "../../../static/images/marker-icon-bad-2x.png";
import iconImprovementRetina from "../../../static/images/marker-icon-improvement-2x.png";
import iconWhiteRetina from "../../../static/images/marker-icon-white-2x.png";
import iconNatureRetina from "../../../static/images/marker-icon-nature-2x.png";
import iconViewRetina from "../../../static/images/marker-icon-view-2x.png";
import iconPlaqueRetina from "../../../static/images/marker-plaque-2x.png";

const getDisplayIcon = (layers, layerID) => {

  for (let i = 0; i < layers.length; i++) {
    if (layers[i].id === layerID) {

      if (layers[i].iconUrl === "marker-icon-good.png")
        return iconGoodRetina;
      else if (layers[i].iconUrl === "marker-icon-bad.png")
        return iconBadRetina;
      else if (layers[i].iconUrl === "marker-icon-improvement.png")
        return iconImprovementRetina;
      else if (layers[i].iconUrl === "marker-icon-nature.png")
        return iconNatureRetina;
      else if (layers[i].iconUrl === "marker-icon-view.png")
        return iconViewRetina;
      else if (layers[i].iconUrl === "marker-plaque.png")
        return iconPlaqueRetina;

    }
  }

  return iconWhiteRetina;
}

export default getDisplayIcon;