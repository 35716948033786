const getAnswerObject = (code, value) => {

  let codeElements = code.split("-");

  let id = `${codeElements[0]}-${codeElements[1]}-${codeElements[2]}`;
  if (codeElements[0] === 'radioother')
    id = `radio-${codeElements[1]}-${codeElements[2]}`;

  let answerObj = {
    id: id, // `${codeElements[0]}-${codeElements[1]}-${codeElements[2]}`,
    type: codeElements[0],
    questionID: codeElements[1],
    formInputID: codeElements[2]
  }

  if (codeElements[0] === "radio")
    answerObj.value = codeElements[3];
  else if (codeElements[0] === "radioother") {
    answerObj.type = "radio";
    answerObj.otherValue = value;
    answerObj.value = codeElements[3];
  }
  else if (codeElements[0] === "textarea")
    answerObj.value = value;

  return answerObj;
}

export default getAnswerObject;