import {customNameDescriptionLabels, defaultLabels} from "../../../configuration";

const getAddMarkerFormLabels = layerID => {

  let labels = defaultLabels;

  customNameDescriptionLabels.forEach(customLabel => {
    if (Number(layerID) === Number(customLabel.layerID)) {
      labels = customLabel;
    }
  })

  return labels;
}

export default getAddMarkerFormLabels;