import React from 'react';
import { AiOutlineMenu } from "react-icons/ai";
import { colours } from "../../configuration";

const Toggler = props => {
    return (
        <div className="toggler">
            <AiOutlineMenu
                onClick={props.toggle}
                size={18}
                color={colours["placecheck-blue"]}
            />
        </div>
    )
}

export default Toggler;