/*
 * Map pins can store additional data in the 'additionalData' column in the database.
 *
 * This was first used for the Wandsworth project with pipe-separated values, but
 * more recently will contain JSON objects.
 *
 */

const parseAdditionalData = additionalData => {
    let isValidJSON = true;

    try {
        JSON.parse(additionalData)
    }
    catch (e) {
        isValidJSON = false;
    }

    if (isValidJSON === true)
        return JSON.parse(additionalData)
    else
        return additionalData;

}

export default parseAdditionalData;