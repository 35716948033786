import React from 'react';

const MarkerEditButtons = props => {

    if (props.isRequestingDelete === true) {
        return (
            <div className={`flex`} style={{width: "100%"}}>
                <div
                    className={`popup-button popup-button-delete`}
                    onClick={() => props.deleteMarker() }
                >
                    {props.isDeleting ? `Deleting` : `Permanently delete`}
                </div>
                <div className={`popup-button`} onClick={() => props.toggleIsRequestingDelete() }>Cancel</div>
            </div>
        )
    }

    if (props.isEditing === true) {
        return (
            <div className={`flex`} style={{width: "100%"}}>
                <div className={`popup-button`} onClick={() => props.toggleEdit() }>Cancel</div>
                <div className={`popup-button`} onClick={() => props.saveMarker() }>
                    {props.isSaving ? `Saving` : `Save`}
                </div>
            </div>
        )
    }
    else {
        return (
            <div className={`flex`} style={{justifyContent: "flex-end", width: "100%"}}>
                <div className={`popup-button`} onClick={() => props.toggleEdit() }>Edit</div>
                <div className={`popup-button`} onClick={() => props.toggleIsRequestingDelete() }>
                    Delete
                </div>
            </div>
        )
    }
}

export default MarkerEditButtons;