import React from 'react';

const LoadingMap = props => {
  return (
    <div style={{marginTop:"10px"}}>
        Loading
    </div>
  )
}

export default LoadingMap;
