import React from 'react';
import { FaCheck } from "react-icons/fa";

import { requestPasswordReset } from "../../utilities/data";
import logo from '../../static/logos/placecheck-logo.svg';
import BackArrow from "../shared/BackArrow";

class ForgotPassword extends React.Component {
  state = {
    username: "",
    isRequestingPasswordReset: false,
    message: "",
    successMessage: ""
  }
  requestPasswordReset = async () => {
    await this.setState({
      isRequestingPasswordReset: true,
      message: ""
    });

    try {
      const requestPasswordResetResult = await requestPasswordReset(this.state.username);

      console.log("requestPasswordResetResult", requestPasswordResetResult)

      if (requestPasswordResetResult.data.isSuccess === true) {
        await this.setState({
          isRequestingPasswordReset: false,
          successMessage: requestPasswordResetResult.data.message
        })
      }

      await this.setState({
        isRequestingPasswordReset: false
      });

    } catch (e) {
      await this.setState({
        isRequestingPasswordReset: false,
        message: "Sorry, an error occurred requesting a password reset - please try again."
      })
      console.log("LoginForm > authenticate > error", e);
    }
  }
  handleKeyPress = async e => {
    if (e.key === 'Enter')
      this.requestPasswordReset();
  }
  handleTextInput = async e => {
    if (e.key === 'Enter')
      this.authenticate();
    let a = {};
    a[e.currentTarget.name] = e.currentTarget.value;
    await this.setState(a);
  }
  render() {
    return (
      <div className={`login-form-container`}>
        <BackArrow to={'/login'} />

        <div className={`login-logo-container`}>
          <img src={logo} alt={`Placecheck logo`} className={`login-logo`}/>
        </div>
        <div>
          Forgotten your password?&nbsp;&nbsp;&nbsp;No problem - we can reset that for you.
        </div>
        <div className={`login-form`}>
          <div className={`form-control border-0`}>
            <div>
              Please enter your email address
            </div>
            <div>
              <input
                className={`form-input`}
                name="username"
                type="text"
                value={this.state.username}
                onChange={this.handleTextInput}
                onKeyPress={this.handleKeyPress}
              />
            </div>
          </div>
        </div>
        <div>
          <button className={`button ${this.state.username === "" && `button-disabled`}`} onClick={this.requestPasswordReset}>
            {this.state.isRequestingPasswordReset ? `Requesting` : `Go`}
          </button>
        </div>
        <div className={`login-error-message`}>
          {this.state.message}
        </div>
        {
          this.state.successMessage !== ""
          &&
          <div className={`login-success-message`}>
            <FaCheck size={14} style={{marginRight: "20px"}}/> {this.state.successMessage}
          </div>
        }
        <div className={`login-forgotten-password-link`}>
          If you did not register with an email address, or if you need assistance please contact support@placecheck.info
        </div>

      </div>
    )
  }
}

export default ForgotPassword;
