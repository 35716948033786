import React from 'react';
import parse from "html-react-parser";
import { DateTime } from "luxon";

import getDisplayIcon from "./utilities/getDisplayIcon";
import {FaRegThumbsDown, FaRegThumbsUp, FaThumbsDown, FaThumbsUp} from "react-icons/fa";

const Comment = props => {
  const { comment } = props;
  const parsedDescription = parse(comment.description);

  const dateParts = comment.dateCreated.split(" ");
  const displayDate = new DateTime.fromISO(`${dateParts[0]}T${dateParts[1]}`);

  console.log(comment);

  return (
    <div className={`comment-container py-3`}>
      <div className={`comment-icon`}>
        <img
          className={`comment-image`}
          src={getDisplayIcon(props.layers, props.comment.layerID)}
          alt={`Icon`}
        />
      </div>
      <div>
        <div className={`comment-name`}>{comment.name}</div>
        <div className={`comment-date`}>{displayDate.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}</div>
          <div className={`comment-date my-1`}>
              <FaRegThumbsUp size={12} /> {comment.upvotes} <FaRegThumbsDown size={12} /> {comment.downvotes}
          </div>
      </div>
      <div className={`comment-description`}>
          {
              comment.imageName !== null
              &&
              <div>
                  <img
                      src={comment.imagePath}
                      alt={comment.imageName}
                      style={{maxWidth: "200px", marginTop: "10px"}}
                  />
              </div>
          }
          <div>{parsedDescription}</div>

      </div>

    </div>
  )
}

export default Comment;