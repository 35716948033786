import React from 'react';
import LoadingMap from "../map/LoadingMap";
import { getAccountData } from "../../utilities/data";
import {AiOutlineUser} from "react-icons/ai";
// import parse from "html-react-parser";
//
// import placecheckLogo from '../static/logos/placecheck-logo.svg';
// import MenuLink from "./shared/MenuLink";

class Account extends React.Component {
    state = {
        isLoading: true,
        accountData: null
    }
    componentDidMount = async () => {
        if (this.props.token !== null)
            await this.loadAccountData();
    }
    componentDidUpdate = async (prevProps) => {
        if (prevProps.token === null && this.props.token !== null)
            this.loadAccountData();
    }
    loadAccountData = async () => {
        // console.log("About to get data with token " + this.props.token)

        const accountData = await getAccountData(this.props.token);

        // console.log(accountData)

        if (accountData.data.isSuccess === true) {
            await this.setState({
                accountData: accountData.data
            });
        }

        await this.setState({isLoading: false});
    }
    render = () => {

        if (this.state.isLoading)
            return <LoadingMap />

        return (
            <div>
                <div className={`flex-center`}>
                    <div className={`user-icon`}>
                        <AiOutlineUser size={42} />
                    </div>
                </div>
                <h1>{this.state.accountData.userData[0].FirstName} {this.state.accountData.userData[0].LastName}</h1>
                <p>Here will be account information</p>
            </div>
        )
    }
}

export default Account;
