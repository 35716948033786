import React from 'react';
import {Link} from "react-router-dom";

const SignUpPanel = () => {
  return (
    <div className={`flex-center`}>
      <div className={`button-panel signup-panel`}>
        <Link className={`sign-up-button`} to={"/register"}>
          Sign up now to add a comment
        </Link>
        <Link className={`sign-up-button`} to={"/login"}>
          Already signed up? Log in
        </Link>
      </div>
    </div>
  )
}

export default SignUpPanel;
