import React from 'react';

import getButtonPanelHelpText from "./utilities/getButtonPanelHelpText";
import getLayerIcon from "./utilities/getLayerIcon";
import getLayerButtonColour from "./utilities/getLayerButtonColour";
import getLayerButtonLabel from "./utilities/getLayerButtonLabel";
import { AiOutlineCheck } from "react-icons/ai";

const ButtonPanel = props => {

  if (props.mapData === null) {
    return (
      <div>
        No map data
      </div>
    )
  }

  if (props.mapData.map.isOpen === "0") {
    return null;
    // return (
    //   <div className={`flex-center`}>
    //     <div className="button-panel button-panel-message">
    //       This Placecheck is now closed
    //     </div>
    //   </div>
    // )
  }

  if (props.mapState.addMarkerStage > 1)
    return null;

  return (
    <React.Fragment>
      {
        (getButtonPanelHelpText(props.mapState) !== "")
        &&
        <div className={`flex-center`}>
          <div className={`button-panel-message`}>
            {getButtonPanelHelpText(props.mapState, props.mapData)}
          </div>
        </div>
      }
      <div className={`flex-center`}>
        {
          props.mapState.addMarkerLayer === null
            ?
            <div className={`d-flex justify-content-between button-panel`}>
              {
                props.mapData.layers.map(layer => {
                  if (layer.defaultMarkerStyleID === -1)
                    return '';
                  return (
                    <div
                      key={layer.id}
                      className={`add-button`}
                      style={{backgroundColor: getLayerButtonColour(layer)}}
                      onClick={() => props.updateMapState({addMarkerLayer: layer})}
                    >
                      <div>
                        {getLayerIcon(layer)}
                      </div>
                      <div className={`add-button-label`}>
                        {getLayerButtonLabel(layer)}
                      </div>
                        <div className={`add-button-label-mobile`}>
                            {getLayerButtonLabel(layer)}
                        </div>
                    </div>
                  )
                })
              }
            </div>
            :
            <div className={`d-flex justify-content-start button-panel`}>
              <div className={`btn btn-secondary m-2`} onClick={() => props.updateMapState({addMarkerStage: 0, markerLocation: [], addMarkerLayer: null})}>
                  Cancel
              </div>
              {
                props.mapState.markerLocation.length > 0
                &&
                <div
                  className={`btn btn-success d-flex align-items-center m-2`}
                  style={{backgroundColor: getLayerButtonColour(props.mapState.addMarkerLayer)}}
                  onClick={() => props.updateMapState({addMarkerStage: 2})}
                >
                  <div>
                    <AiOutlineCheck size={18}/>
                  </div>
                  <div className={`add-button-label`}>
                    Confirm location
                  </div>
                  <div className={`add-button-label-mobile`}>
                    Confirm location
                  </div>
                </div>
              }
            </div>
        }
      </div>
        {
            props.mapState.addMarkerLayer === null
            &&
            <div
                style={{visibility: props.mapState.markerLocation.length === 0 ? `hidden` : `visible`, marginTop: "200px"}}
                className={`add-button map-back-arrow`}
                onClick={() => props.updateMapState({addMarkerStage: 0, markerLocation: []})}
            >
                <div className={`button-back`}>Cancel</div>
            </div>
        }
    </React.Fragment>
  )

}

export default ButtonPanel;
