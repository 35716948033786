import React from 'react';

const TermsAndConditions = props => {
    return (
        <div>
            <h5>1. Who are we?</h5>
            <p>
                Placecheck Online is a partnership between UDS Planning Ltd (a company registered in the UK No. 08135381) and Charlbury Technology Limited (a company registered in the UK No. 10744709).
                The partnership is described as 'We' in these terms.
            </p>

            <h5>2. Using Placecheck Online</h5>
            <p>You agree to use Placecheck Online (described as the 'site' in these terms, meaning www.placecheck.info) only for lawful purposes.</p>
            <p>You must also use it in a way that does not infringe the rights of, or restrict or inhibit the use and enjoyment of the site by anyone else.</p>
            <p>
                By using the site, you agree to comply with and be bound by these terms.
                You must not use the site if you do not agree.
            </p>

            <h5>3. Copyright</h5>
            <p>Most content on the site is subject to copyright protection.</p>
            <p>
                If you add content to the site, for example by adding a map pin, then you own the copyright to the content you submit.
                By adding content, you agree to provide us with an unlimited worlwide licence to use this content, including publishing it in on the site.
                You also agree to provide a sub-licence to others to use the content.
                For example, if a Placecheck map is supporting a public consultation, then the comments that you add may be passed to the organisation which is running the consultation.
            </p>
            <p>
                We agree to keep your name and email address private and these will not be passed on to any third parties without your explicit consent.
                We may contact you ourselves if requested by an organization or person who is running a Placecheck map.
            </p>

            <h5>4. Disclaimer</h5>
            <p>We will make reasonable efforts to keep the site up to date, but we do not provide any guarantees, conditions or warranties that the site will be current, secure, accurate, complete or free from bugs.</p>
            <p>
                We disclaim any liability for any loss or damage that may come from the use of the site.
                This includes any direct or consequential losses, any loss or damage caused by civil wrongs ('tort', including negligence) breach of contract or otherwise.
            </p>
            <p>This also includes the use of our site and any sites that are linked from it, and any loss or damage arising from the inability to use the site.</p>
            <p>This applies if the loss or damage was foreseeable, if it arose in the normal course of things, or if you advised us it might happen.</p>
            <p>This disclaimer does not apply to death or personal injury arising from our negligence, or any other liability which cannot be excluded in law.</p>

            <h5>5. Requests to remove content</h5>
            <p>You can ask for content to be removed from a Placecheck map, or any part of the site, if it breaches data protection legislation covering the rights and freedoms of individuals, if it breaches copyright law, contains sensitive personal data, or contains material that may be considered obscene or defamatory.</p>
            <p>To request removal of content, contact us at admin@placecheck.info with a) the URL of the page containing the information, b) details of the information, including a description of the location if the content is a map pin.</p>

            <h5>6. Tracking</h5>
            <p>We may introduce new features at any time which collect information about visitors to the site, in accordance with our privacy policy.</p>
            <p>By using the site, you agree to us collecting this information.</p>

            <h5>7. Hacking</h5>
            <p>You agree not to try and gain unauthorized access to the site, including its servers, databases, APIs or any other connected system.</p>
            <p>You also agree not to attack the site, including any form of denial-of-service attacks.</p>

            <h5>8. General</h5>
            <p>We may update these terms at any time without notice, and you will agree to these terms if you continue to use the site after they have been updated.</p>
            <p>If any of these terms and conditions are held to be unenforceable or illegal for any reason, the remaining terms and conditions will still apply.</p>
            <p>These terms are governed by the law of England and Wales, and any disputes will be subject to the exclusive jurisdiction of the courts of England and Wales.</p>
        </div>
    )
}

export default TermsAndConditions;