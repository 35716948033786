import React, {useState} from 'react';
import { passwordStrength } from 'check-password-strength';
import { useNavigate, useParams } from "react-router-dom";

import { authenticate, resetPassword } from "../../utilities/data";
import logo from '../../static/logos/placecheck-logo.svg';
import BackArrow from "../shared/BackArrow";

const ResetPassword = props => {

  const params = useParams();
  const navigate = useNavigate();

  const [ password, setPassword ] = useState("");
  const [ verifyPassword, setVerifyPassword ] = useState("");
  const [ errorMessage, setErrorMessage ] = useState("");
  const [ isPasswordsValid, setIsPasswordsValid ] = useState("");
  const [ isResettingPassword, setIsResettingPassword ] = useState(false);
  const [ isTokenAuthenticated, setIsTokenAuthenticated ] = useState(true);
  const [ currentPasswordStrength, setCurrentPasswordStrength ] = useState("");

  const checkPasswordMatch = async () => {
    if ((password !== verifyPassword) && verifyPassword !== "") {
      setErrorMessage("Passwords do not match");
    }
    else if (passwordStrength === "Weak")
      setErrorMessage("Weak password - please try a longer password or use more letters, numbers and symbols");
    else {
      setErrorMessage("");
    }
  }

  const checkIfPasswordsAreValid = async () => {
    if (passwordStrength === "Weak")
      return false;
    if (passwordStrength === "")
      return false;

    return password === verifyPassword;
  }

  const requestPasswordReset = async () => {
    if (isPasswordsValid === false)
      return null;

    setIsResettingPassword(true);
    setErrorMessage("");

    try {
      const resetPasswordResult = await resetPassword(password, params.token);

      console.log("resetPasswordResult", resetPasswordResult)

      if (resetPasswordResult.data.isSuccess === true) {
        setErrorMessage("");

        let authResult = await authenticate(resetPasswordResult.data.email, password);

        if (authResult.data.isAuthenticated === true) {
          await props.updateAppState({
            userID: Number(authResult.data.userID),
            token: authResult.data.token,
            isResettingPassword: false
          })
          navigate("/");
        }
        else {
          setErrorMessage("Sorry an error occurred when logging in - please contact support@placecheck.info for assistance");
        }
      }
      else {
        let message = resetPasswordResult.data.message;
        if (message === "")
          message = "Sorry an error occurred. Please contact support@placecheck.info";
        setErrorMessage(message);
      }

      setIsResettingPassword(false);

    } catch (e) {
      console.log("Error", e)
      setIsResettingPassword(false);
      setErrorMessage("Sorry an error occurred while resetting your password - please contact support@placecheck.info for assistance");
    }
  }

  const handleKeyPress = async e => {
    if (e.key === 'Enter')
      requestPasswordReset();
  }

  const handleTextInput = async e => {
    if (e.key === 'Enter')
      authenticate();

    if (e.currentTarget.name === "password") {
      setPassword(e.currentTarget.value);

      if (e.currentTarget.value === "")
        setCurrentPasswordStrength("");
      else
        setCurrentPasswordStrength(passwordStrength(e.currentTarget.value).value)
    }

    if (e.currentTarget.name === "verifyPassword")
      setVerifyPassword(e.currentTarget.value);

    setIsPasswordsValid(checkIfPasswordsAreValid());

  }

  return (
    <div className={`login-form-container`}>
      <BackArrow to={'/'} />
      <div className={`login-logo-container`}>
        <img src={logo} alt={`Placecheck logo`} className={`login-logo`}/>
      </div>
      <div>
        {
          isTokenAuthenticated
          ?
          "Please enter a new password for your account"
          :
          "Authenticating your password reset token - please wait"
        }
      </div>
      {
        isTokenAuthenticated
        &&
        <React.Fragment>
          <div className={`login-form`}>
            <div className={`form-control border-0`}>
              <div>
                Password
              </div>
              <div>
                <input
                  autoComplete="off"
                  className={`form-input`}
                  name="password"
                  type="password"
                  value={password}
                  onChange={handleTextInput}
                  onBlur={checkPasswordMatch}
                />
              </div>
            </div>
            <div className={`form-control border-0`}>
              <div>
                Enter your password again to confirm
              </div>
              <div>
                <input
                  autoComplete="off"
                  className={`form-input`}
                  name="verifyPassword"
                  type="password"
                  value={verifyPassword}
                  onChange={handleTextInput}
                  onBlur={checkPasswordMatch}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
          </div>
          <div>
            <button className={`button ${isPasswordsValid === false && `button-disabled`}`} onClick={requestPasswordReset}>
              {isResettingPassword ? `Working` : `Reset password`}
            </button>
          </div>
          <div>
            {
              currentPasswordStrength !== ""
              &&
              `Password strength: ${currentPasswordStrength}`
            }
          </div>
          <div className={`login-error-message`}>
            {errorMessage}
          </div>
        </React.Fragment>
      }
    </div>
  )
}

export default ResetPassword;
