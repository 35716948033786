import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { withCookies } from 'react-cookie';

import './css/app.css';
import './css/map.css';
import './css/login.css';
import './css/popup.css';
import './css/questionnaire.css';

import About from "./components/About"
import Account from "./components/account/Account";
import {authenticateToken} from "./utilities/data";
import Comments from "./components/comments/Comments";
import { cookieName } from "./configuration";
import Header from "./components/header/Header";
import Login from "./components/auth/Login";
import Logout from "./components/auth/Logout";
import ForgotPassword from "./components/auth/ForgotPassword";
import Register from "./components/auth/Register";
import ResetPassword from "./components/auth/ResetPassword";
import MapPage from "./components/map/MapPage.js";
import Home from "./components/home/Home";

class App extends React.Component {
  state = {
    // hasShownStartupInfo: false,
    isAuthenticating: true,
    isMapLoading: true,
    slug: null,
    mapID: null,
    userID: null,
    token: null,
    mapData: null
  }
  componentDidMount = async () => {
    let authToken = this.props.cookies.get(cookieName);

    if (authToken !== undefined) {

      const authResult = await authenticateToken(authToken);

      if (authResult !== undefined) {
        await this.setState({
          userID: authResult.data.userID,
          token: authToken
        });
      }
    }
    await this.setState({isAuthenticating: false});

    window.onbeforeunload = function() {
      return true;
    }
  }
  removeCookie = () => {
    this.props.cookies.remove(cookieName, {path:"/"});
  }
  setCookie = token => {
    this.props.cookies.set(cookieName, token, {path:"/"});
  }
  updateAppState = async obj => {
    await this.setState(obj);
  }
  render() {
    return (
      <div className="App">

        {/*<HeaderBar />*/}
        <Header
          userID={this.state.userID}
          token={this.state.token}
          updateAppState={this.updateAppState}
          isAuthenticating={this.state.isAuthenticating}
          slug={this.state.slug}
          mapData={this.state.mapData}
        />

        <Routes>

          <Route path={"/"} exact element={<Home />} />

          <Route
            path={"/maps/:slug"}
            element={
              <MapPage
                isMapLoading={this.state.isMapLoading}
                userID={this.state.userID}
                token={this.state.token}
                mapData={this.state.mapData}
                updateAppState={this.updateAppState}
              />
            }
          />

          <Route
            path={"/about"}
            element={
              <About
                updateAppState={this.updateAppState}
                mapData={this.state.mapData}
              />
            }
          />

          <Route
            path={"/comments"}
            element={
              <Comments
                updateAppState={this.updateAppState}
                mapData={this.state.mapData}
                slug={this.state.slug}
              />
            }
          />

          <Route path={"/account"} element={ <Account token={this.state.token} /> } />

          <Route
            path={"/login"}
            element={
              <Login
                updateAppState={this.updateAppState}
                setCookie={this.setCookie}
                slug={this.state.slug}
              />
            }
          />

          <Route
            path={"/forgotPassword"}
            element={
              <ForgotPassword
                updateAppState={this.updateAppState}
                slug={this.state.slug}
              />
            }
          />

          <Route
            path={"/resetPassword/:token"}
            element={
              <ResetPassword
                updateAppState={this.updateAppState}
                slug={this.state.slug}
              />
            }
          />

          <Route
            path={"/register"}
            element={
              <Register
                updateAppState={this.updateAppState}
                slug={this.state.slug}
              />
            }
          />

          <Route
            path={"/logout"}
            element={
              <Logout
                token={this.state.token}
                updateAppState={this.updateAppState}
                removeCookie={this.removeCookie}
                slug={this.state.slug}
              />
            }
          />

        </Routes>

      </div>
    );
  }
}

export default withCookies(App);
