const getFileSizeDescription = bytes => {
    var fileSizeDescription = 0;

    if (bytes < 1000)
        fileSizeDescription = bytes + ' bytes';
    else if (bytes >= 1000 && bytes < 1000000)
        fileSizeDescription = (bytes / 1000).toFixed(1) + ' KB';
    else if (bytes > 1000000 && bytes < 800000000)
        fileSizeDescription = (bytes / 1000000).toFixed(1) + ' MB';
    else if (bytes > 800000000)
        fileSizeDescription = (bytes / 1000000000).toFixed(2) + ' GB';

    return fileSizeDescription;
};

export default getFileSizeDescription;