const getButtonPanelHelpText = (mapState, mapData) => {

  // console.log("Getting button panel help text", mapState, mapData)

  if (mapData !== undefined) {
    if (mapData.map.specialType === 'questionnaire') {
      // console.log("getButtonPanelHelpText > specialType", mapData.map.specialType)

      if (mapState.addMarkerStage === 0) {
        if (mapState.answeredQuestions.length === 0)
          return "Select a numbered map pin to read more and answer questions or click anywhere on the map to add a comment"
        else
          return mapState.answeredQuestions.length + " questions answered. Click a question, or anywhere on the map to add a comment";
      }

    }
  }



  if (mapState.addMarkerStage === 0) {
    return "Click on the map to place a map pin";
  }


  if (mapState.addMarkerStage > 0 && mapState.addMarkerLayer === null) {
    if (mapData === undefined)
      return "Select the type of pin you want to add";
    else {
      if (mapData.layers.length > 1)
        return "Select the type of pin you want to add";
      else {
        if (Number(mapData.layers[0].id) === 1363)
          return "Drag your map pin or press the green button to confirm"
      }
    }
  }

  if (mapState.addMarkerStage === 1 && mapState.addMarkerLayer !== null)
    return "Drag map pin or click on map to adjust position if needed"

  return "";
}

export default getButtonPanelHelpText;
