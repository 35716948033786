import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

import { authenticate } from "../../utilities/data";
import logo from '../../static/logos/placecheck-logo.svg';
import BackArrow from "../shared/BackArrow";

const Login = props => {

  const [ username, setUsername ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ isLoggingIn, setIsLoggingIn ] = useState(false);
  const [ isProblemLoggingIn, setIsProblemLoggingIn ] = useState(false)
  const [ message, setMessage ] = useState("");

  const navigate = useNavigate();

  const authenticateUser = async () => {

    setIsLoggingIn(true);
    setIsProblemLoggingIn(false);
    setMessage("");

    try {
      const authResult = await authenticate(username, password);

      // console.log("Login > authenticate > authResult ", authResult)

      if (authResult.data.isAuthenticated === true) {
        await props.updateAppState({
          userID: Number(authResult.data.userID),
          token: authResult.data.token
        })
        setIsLoggingIn(false);
        props.setCookie(authResult.data.token);

        // console.log("Should be navigating to " + `/maps/${props.slug !== null ? props.slug : ""}`)
        navigate(`/maps/${props.slug !== null ? props.slug : ""}`)
        // props.history.push(`/maps/${this.props.slug !== null ? this.props.slug : ""}`);
      }

    } catch (e) {

      console.log("Error during authentication", e)

      setIsLoggingIn(false);
      setMessage("Sorry, an error occurred logging in - please try again.")

    }

  }

  const handleKeyPress = async e => {
    if (e.key === 'Enter')
      authenticateUser();
  }

  const handleTextInput = async e => {
    if (e.currentTarget.name === "username")
      setUsername(e.currentTarget.value);
    if (e.currentTarget.name === "password")
      setPassword(e.currentTarget.value);
  }

  return (
    <div className={`login-form-container`}>
      <BackArrow to={`/maps/${props.slug !== null ? props.slug : ""}`} />

      <div className={`login-logo-container`}>
        <img src={logo} alt={`Placecheck logo`} className={`login-logo`}/>
      </div>
      <div className={`login-form`}>
        <div className={`form-control border-0`}>
          <div className={`form-label`}>Email</div>
          <div>
            <input className={`form-input`} name="username" type="text" value={username} onChange={handleTextInput} />
          </div>
        </div>
        <div className={`form-control border-0`}>
          <div className={`form-label`}>
            Password
          </div>
          <div>
            <input className={`form-input`} name="password" type="password" value={password} onChange={handleTextInput} onKeyPress={handleKeyPress}/>
          </div>
        </div>
      </div>
      <div>
        <button className={`button`} onClick={authenticateUser}>
          {isLoggingIn ? `Signing in` : `Go`}
        </button>
      </div>
      <div className={`login-error-message`}>
        {message}
      </div>

      <Link to={`/forgotPassword`} className={`login-forgotten-password-link`}>
        Forgotten your password?
      </Link>


    </div>
  )
}

export default Login;
